var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout create-product-content" },
    [
      _vm.showModalProducts
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "template-modal" },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "modal-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("selectProduct") + _vm.selectedProductLevel
                            )
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "md-layout-item md-size-66" },
                          [
                            _c(
                              "md-field",
                              [
                                _c("label", [_vm._v(_vm._s(_vm.$t("search")))]),
                                _c("md-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.selectedProductLevel === 1
                          ? _c(
                              "div",
                              {
                                key: _vm.renderKey,
                                staticClass:
                                  "md-layout modal-content modal-list",
                              },
                              _vm._l(_vm.filteredLevelOne, function (level_1) {
                                return _c(
                                  "div",
                                  {
                                    key: level_1.ProductLevelOneID,
                                    staticClass:
                                      "md-layout-item md-size-100 product-level-list",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "list-row",
                                        class: { selected: level_1.selected },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "list-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.add_To_Selection(
                                                  level_1
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(level_1.Name) + " "
                                            ),
                                          ]
                                        ),
                                        _c("div", { staticClass: "controls" }, [
                                          _c(
                                            "div",
                                            { staticClass: "icon-container" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "animated-icon",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleAddLevelOne(
                                                        level_1
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("add")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "animated-icon",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteProductLevel(
                                                        level_1,
                                                        1
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("remove")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm.selectedProductLevel === 2
                          ? _c(
                              "div",
                              {
                                key: _vm.renderKey,
                                staticClass:
                                  "md-layout modal-content modal-list",
                              },
                              _vm._l(_vm.filteredLevelTwo, function (level_2) {
                                return _c(
                                  "div",
                                  {
                                    key: level_2.ProductLevelTwoID,
                                    staticClass:
                                      "md-layout-item md-size-100 product-level-list",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "list-row",
                                        class: { selected: level_2.selected },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "list-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.add_To_Selection(
                                                  level_2
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(level_2.Name))]
                                        ),
                                        _c("div", { staticClass: "controls" }, [
                                          _c(
                                            "div",
                                            { staticClass: "icon-container" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "animated-icon",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleAddLevelTwo(
                                                        level_2
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("add")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "animated-icon",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteProductLevel(
                                                        level_2,
                                                        2
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("remove")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm.selectedProductLevel === 3
                          ? _c(
                              "div",
                              {
                                key: _vm.renderKey,
                                staticClass:
                                  "md-layout modal-content modal-list",
                              },
                              _vm._l(
                                _vm.filteredLevelThree,
                                function (level_3) {
                                  return _c(
                                    "div",
                                    {
                                      key: level_3.ProductLevelThreeID,
                                      staticClass:
                                        "md-layout-item md-size-100 product-level-list",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "list-row",
                                          class: { selected: level_3.selected },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "list-item",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.add_To_Selection(
                                                    level_3
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(level_3.Name))]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "controls" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "icon-container",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "animated-icon",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleAddLevelThree(
                                                            level_3
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("add")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "animated-icon",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteProductLevel(
                                                            level_3,
                                                            3
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("remove")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1896020570
              ),
            },
            [
              _c("template", { slot: "footer" }, [
                _c("div", { staticClass: "md-layout space-evenly" }, [
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-33 just-center" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-round md-primary",
                          on: { click: _vm.createProductLevel },
                        },
                        [_vm._v(_vm._s(_vm.$t("create")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-33 just-center" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-round md-primary",
                          attrs: { disabled: _vm.level_Selection.length <= 0 },
                          on: { click: _vm.handle_Add_Selection },
                        },
                        [_vm._v(_vm._s(_vm.$t("confirmSelected")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-33 just-center" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-round md-simple",
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v(_vm._s(_vm.$t("cancel")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.showModalCreateLevel
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "template-modal" },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "modal-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("createProductLevel") +
                                _vm.selectedProductLevel
                            )
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("EditConfigurationProductLevel", {
                          attrs: {
                            editProduct: _vm.editProduct,
                            productLevel: _vm.selectedProductLevel,
                          },
                          on: {
                            update: _vm.updateProducts,
                            close: _vm.closeModal,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2605786190
              ),
            },
            [
              _c("template", { slot: "footer" }, [
                _c("div", { staticClass: "create-level-one-content" }),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.showCopyConfigModal
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "template-modal" },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "title-container" }, [
                          _c("h4", { staticClass: "modal-title" }, [
                            _vm._v(_vm._s(_vm.$t("copyOrCreateTitle"))),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "copy-config-content" },
                          [
                            _vm._l(_vm.configsToCopy, function (config) {
                              return _c(
                                "div",
                                {
                                  key: config.ID,
                                  staticClass: "copy-config-item list-row",
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyAndAddMaster(config)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(config.Info) + " ")]
                              )
                            }),
                            _c("div", { staticClass: "pt-15 md-layout" }, [
                              _c(
                                "div",
                                { staticClass: "md-layout-item md-size-33" },
                                [
                                  _c(
                                    "md-button",
                                    {
                                      staticClass: "md-round md-simple",
                                      on: { click: _vm.closeModal },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "md-layout-item md-size-33" },
                                [
                                  _c(
                                    "md-button",
                                    {
                                      staticClass: "md-round md-primary",
                                      on: { click: _vm.handleAddEmptyMaster },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("new")))]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                852631388
              ),
            },
            [_c("template", { slot: "footer" }, [_c("div", {})])],
            2
          )
        : _vm._e(),
      _vm.showModalCreateConfigurationProductLevel
        ? _c("modal", {
            staticClass: "modal-content",
            attrs: { name: "template-modal" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("createConfigurationHasProductLevel") +
                              _vm.selectedProductLevel
                          )
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("EditConfigurationHasProductLevel", {
                        attrs: {
                          productLevel: _vm.selectedProductLevel,
                          configurationID: _vm.propConfigurationID,
                          productLevelOneID: _vm.propProductLevelOneID,
                          productLevelTwoID: _vm.propProductLevelTwoID,
                          productLevelThreeID: _vm.propProductLevelThreeID,
                          productLevelSelection: _vm.level_Selection,
                          editProduct: _vm.editConfigHasProduct,
                        },
                        on: {
                          close: _vm.closeModal,
                          update: _vm.updateProducts,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3826240236
            ),
          })
        : _vm._e(),
      _vm.showModalTechDep
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "template-modal" },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.$t("techDep"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("EditTechnicalDependency", {
                          attrs: {
                            productID: _vm.product.ID,
                            productLevelOneID: _vm.propProductLevelOneID,
                          },
                          on: {
                            close: _vm.closeModal,
                            update: _vm.updateProducts,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1872698088
              ),
            },
            [
              _c("template", { slot: "footer" }, [
                _c("div", { staticClass: "create-level-one-content" }),
              ]),
            ],
            2
          )
        : _vm._e(),
      _c("ValidationObserver", {
        staticClass: "md-layout-item md-size-50 md-medium-size-100",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  [
                    _c("loader", { attrs: { loader: _vm.loader } }),
                    _c(
                      "md-card",
                      { staticClass: "card-content create-product-card" },
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass: "md-card-header-icon header-style",
                            class: _vm.getClass(_vm.headerColor),
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "card-icon" },
                                [_c("md-icon", [_vm._v("settings")])],
                                1
                              ),
                              !_vm.edit
                                ? _c("h3", { staticClass: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("createProductCardTitle")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("h3", { staticClass: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("updateProductCardTitle")
                                        ) +
                                        " "
                                    ),
                                  ]),
                              _c("h4", [
                                _vm._v(_vm._s(_vm.$t("createProductText"))),
                              ]),
                            ]),
                          ]
                        ),
                        _c("md-card-content", [
                          _c(
                            "form",
                            { attrs: { enctype: "multipart/form-data" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "md-layout-item upload-document",
                                },
                                [
                                  _c("div", { staticClass: "file-input" }, [
                                    !_vm.imageRegular
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "image-container" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.regularImg,
                                                  title: "",
                                                },
                                              }),
                                            ]
                                          ),
                                        ])
                                      : _c(
                                          "div",
                                          { staticClass: "image-container" },
                                          [
                                            _c("img", {
                                              attrs: { src: _vm.imageRegular },
                                            }),
                                          ]
                                        ),
                                    _c(
                                      "div",
                                      { staticClass: "button-container" },
                                      [
                                        _vm.imageRegular
                                          ? _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-primary md-round md-fileinput ima-submit",
                                                on: { click: _vm.uploadImage },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("upload")) + " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.imageRegular
                                          ? _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-round md-simple",
                                                on: { click: _vm.removeImage },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("remove")))]
                                            )
                                          : _vm._e(),
                                        !_vm.imageRegular
                                          ? _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-round md-primary md-fileinput ima-change",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("selectImg"))
                                                ),
                                                _c("input", {
                                                  attrs: { type: "file" },
                                                  on: {
                                                    change: _vm.onFileChange,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-round md-simple md-fileinput ima-change",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("change"))
                                                ),
                                                _c("input", {
                                                  attrs: { type: "file" },
                                                  on: {
                                                    change: _vm.onFileChange,
                                                  },
                                                }),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("productIDExtern"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.product.Product,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.product, "Product", $$v)
                                        },
                                        expression: "product.Product",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(_vm.$t("productBuyLinkExtern"))
                                      ),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.product.ProductBuyLinkExtern,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.product,
                                            "ProductBuyLinkExtern",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "product.ProductBuyLinkExtern",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "pName", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var passed = ref.passed
                                          var failed = ref.failed
                                          return [
                                            _c(
                                              "md-field",
                                              {
                                                class: [
                                                  { "md-error": failed },
                                                  { "md-valid": passed },
                                                ],
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("productName") +
                                                        " *"
                                                    )
                                                  ),
                                                ]),
                                                _c("md-input", {
                                                  attrs: { type: "text" },
                                                  model: {
                                                    value: _vm.product.Name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.product,
                                                        "Name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "product.Name",
                                                  },
                                                }),
                                                _c(
                                                  "slide-y-down-transition",
                                                  [
                                                    _c(
                                                      "md-icon",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: failed,
                                                            expression:
                                                              "failed",
                                                          },
                                                        ],
                                                        staticClass: "error",
                                                      },
                                                      [_vm._v("close")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "slide-y-down-transition",
                                                  [
                                                    _c(
                                                      "md-icon",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: passed,
                                                            expression:
                                                              "passed",
                                                          },
                                                        ],
                                                        staticClass: "success",
                                                      },
                                                      [_vm._v("done")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "pLicName",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var passed = ref.passed
                                              var failed = ref.failed
                                              return [
                                                _c(
                                                  "md-field",
                                                  {
                                                    class: [
                                                      { "md-error": failed },
                                                      { "md-valid": passed },
                                                    ],
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "productLicName"
                                                          ) + " *"
                                                        )
                                                      ),
                                                    ]),
                                                    _c("md-input", {
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value:
                                                          _vm.product.LicName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.product,
                                                            "LicName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "product.LicName",
                                                      },
                                                    }),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: failed,
                                                                expression:
                                                                  "failed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [_vm._v("close")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: passed,
                                                                expression:
                                                                  "passed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "success",
                                                          },
                                                          [_vm._v("done")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "md-field",
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("productRuntime"))
                                          ),
                                        ]),
                                        _c("md-input", {
                                          attrs: { type: "number" },
                                          model: {
                                            value: _vm.product.RuntimeInDays,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.product,
                                                "RuntimeInDays",
                                                $$v
                                              )
                                            },
                                            expression: "product.RuntimeInDays",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "md-field",
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("productVersion"))
                                          ),
                                        ]),
                                        _c("md-input", {
                                          attrs: { type: "number" },
                                          model: {
                                            value: _vm.product.Version,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.product,
                                                "Version",
                                                $$v
                                              )
                                            },
                                            expression: "product.Version",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "pTitle", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var passed = ref.passed
                                          var failed = ref.failed
                                          return [
                                            _c(
                                              "md-field",
                                              {
                                                class: [
                                                  { "md-error": failed },
                                                  { "md-valid": passed },
                                                ],
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createProductTitle"
                                                      ) + " *"
                                                    )
                                                  ),
                                                ]),
                                                _c("md-input", {
                                                  attrs: { type: "text" },
                                                  model: {
                                                    value: _vm.product.Title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.product,
                                                        "Title",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "product.Title",
                                                  },
                                                }),
                                                _c(
                                                  "slide-y-down-transition",
                                                  [
                                                    _c(
                                                      "md-icon",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: failed,
                                                            expression:
                                                              "failed",
                                                          },
                                                        ],
                                                        staticClass: "error",
                                                      },
                                                      [_vm._v("close")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "slide-y-down-transition",
                                                  [
                                                    _c(
                                                      "md-icon",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: passed,
                                                            expression:
                                                              "passed",
                                                          },
                                                        ],
                                                        staticClass: "success",
                                                      },
                                                      [_vm._v("done")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "pTitleEN",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var passed = ref.passed
                                              var failed = ref.failed
                                              return [
                                                _c(
                                                  "md-field",
                                                  {
                                                    class: [
                                                      { "md-error": failed },
                                                      { "md-valid": passed },
                                                    ],
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "productTitleEN"
                                                          ) + " *"
                                                        )
                                                      ),
                                                    ]),
                                                    _c("md-input", {
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value:
                                                          _vm.product.TitleEN,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.product,
                                                            "TitleEN",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "product.TitleEN",
                                                      },
                                                    }),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: failed,
                                                                expression:
                                                                  "failed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [_vm._v("close")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: passed,
                                                                expression:
                                                                  "passed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "success",
                                                          },
                                                          [_vm._v("done")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("productSubtitle"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.product.Subtitle,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.product, "Subtitle", $$v)
                                        },
                                        expression: "product.Subtitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "md-field",
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("productSubtitleEN"))
                                          ),
                                        ]),
                                        _c("md-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.product.SubtitleEN,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.product,
                                                "SubtitleEN",
                                                $$v
                                              )
                                            },
                                            expression: "product.SubtitleEN",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  { staticClass: "md-has-value no-bg" },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("productTeaser"))),
                                    ]),
                                    _c("md-textarea", {
                                      model: {
                                        value: _vm.product.Teaser,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.product, "Teaser", $$v)
                                        },
                                        expression: "product.Teaser",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "md-field",
                                      { staticClass: "md-has-value no-bg" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("productTeaserEN"))
                                          ),
                                        ]),
                                        _c("md-textarea", {
                                          model: {
                                            value: _vm.product.TeaserEN,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.product,
                                                "TeaserEN",
                                                $$v
                                              )
                                            },
                                            expression: "product.TeaserEN",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("productLink"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.product.Link,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.product, "Link", $$v)
                                        },
                                        expression: "product.Link",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "md-field",
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("productLinkEN"))
                                          ),
                                        ]),
                                        _c("md-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.product.LinkEN,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.product,
                                                "LinkEN",
                                                $$v
                                              )
                                            },
                                            expression: "product.LinkEN",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "md-field",
                                      { staticClass: "md-has-value" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("productStartTime"))
                                          ),
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.product.Starttime,
                                              expression: "product.Starttime",
                                            },
                                          ],
                                          staticClass: "md-input",
                                          attrs: { type: "date" },
                                          domProps: {
                                            value: _vm.product.Starttime,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.product,
                                                "Starttime",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "md-field",
                                      { staticClass: "md-has-value" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("productEndTime"))
                                          ),
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.product.Endtime,
                                              expression: "product.Endtime",
                                            },
                                          ],
                                          staticClass: "md-input",
                                          attrs: { type: "date" },
                                          domProps: {
                                            value: _vm.product.Endtime,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.product,
                                                "Endtime",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "update-profile-button" },
                              [
                                _c(
                                  "div",
                                  {
                                    key: _vm.renderKey,
                                    staticClass:
                                      "md-layout-item md-size-100 text-right",
                                  },
                                  [
                                    !_vm.edit
                                      ? _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-raised md-primary mt-4 md-round",
                                            attrs: { disabled: invalid },
                                            on: { click: _vm.handleCreate },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("createProduct")) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-raised md-primary mt-4 md-round",
                                            attrs: { disabled: invalid },
                                            on: { click: _vm.handleUpdate },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("save")) + " ")]
                                        ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "text-center" }),
                                _c("form", {
                                  attrs: { enctype: "multipart/form-data" },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm.allow("{0bc5cd90-e97e-44fc-94ea-014b2f57a6b4}")
        ? _c(
            "div",
            { staticClass: "md-layout-item md-size-50 md-medium-size-100" },
            [
              _c(
                "md-card",
                [
                  _c(
                    "tabs",
                    {
                      staticClass: "tab-product-levels r-3",
                      attrs: {
                        "tab-name": [
                          _vm.$t("productLevel") + "1",
                          _vm.$t("productLevel") + "2",
                          _vm.$t("productLevel") + "3",
                        ],
                        "tab-icon": ["looks_one", "looks_two", "looks_3"],
                        "nav-pills-icons": "",
                        plain: "",
                        "color-button": "primary",
                      },
                    },
                    [
                      _vm.masterConfig !== null
                        ? _c(
                            "h4",
                            {
                              staticClass: "title text-center",
                              attrs: { slot: "header-title" },
                              slot: "header-title",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("masterTemplate")) + " - "
                              ),
                              _c("small", { staticClass: "description" }, [
                                _vm._v(_vm._s(_vm.product.Name)),
                              ]),
                            ]
                          )
                        : _c(
                            "h4",
                            {
                              staticClass: "title text-center",
                              attrs: { slot: "header-title" },
                              slot: "header-title",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("noMasterFound")) + " - "
                              ),
                              _c("small", { staticClass: "description" }, [
                                _vm._v(_vm._s(_vm.$t("createNewMaster"))),
                              ]),
                            ]
                          ),
                      _c("template", { slot: "tab-pane-1" }, [
                        _c(
                          "div",
                          { staticClass: "md-layout" },
                          [
                            _vm.masterConfig !== null &&
                            _vm.masterModel.ProductsLevel_1.length === 0
                              ? _c(
                                  "div",
                                  { staticClass: "md-layout-item md-size-100" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("noProductsLevelOneYet"))
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._l(
                              _vm.masterModel.ProductsLevel_1,
                              function (level_one) {
                                return _c(
                                  "div",
                                  {
                                    key: level_one.ProductLevelOneID,
                                    staticClass: "level-one-container",
                                    class: {
                                      "has-items":
                                        level_one.ProductsLevel_2.length > 0,
                                      selected:
                                        _vm.selectedLevelOne
                                          .ProductLevelOneID ===
                                        level_one.ProductLevelOneID,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectLevelOne(level_one)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(level_one.Name)),
                                    ]),
                                    _c("div", { staticClass: "controls" }, [
                                      _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEditLevelOne(
                                                    level_one
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("edit")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("editProductTooltip")
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleConfigHasProd_1(
                                                    level_one
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("add")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "editProductLevelTooltip"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleTechDep(
                                                    level_one
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("rule")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("addTechDepTooltip")
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDeleteConfigProd(
                                                    level_one,
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("delete")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "deleteFromMasterTooltip"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            _c("div", {
                              staticClass: "md-layout-item md-size-50 center",
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "md-layout-item md-size-50 center",
                              },
                              [
                                !_vm.noMasterModel
                                  ? _c(
                                      "md-button",
                                      {
                                        staticClass: "md-primary md-round",
                                        on: { click: _vm.askChooseLevelOne },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("add")))]
                                    )
                                  : _c(
                                      "md-button",
                                      {
                                        staticClass: "md-primary md-round",
                                        on: {
                                          click: _vm.handleCreateMasterConfig,
                                        },
                                      },
                                      [
                                        _vm._v(_vm._s(_vm.$t("create"))),
                                        _c("md-tooltip", [
                                          _vm._v(
                                            _vm._s(_vm.$t("createMaster"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]),
                      _c("template", { slot: "tab-pane-2" }, [
                        _c(
                          "div",
                          { staticClass: "md-layout" },
                          [
                            !_vm.selectedLevelOne.Name
                              ? _c(
                                  "div",
                                  { staticClass: "md-layout-item md-size-100" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("noProductLevelOneSelected")
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._l(
                              _vm.selectedLevelOne.ProductsLevel_2,
                              function (level_two) {
                                return _c(
                                  "div",
                                  {
                                    key: level_two.ProductLevelTwoID,
                                    staticClass: "level-two-container",
                                    class: {
                                      "has-items":
                                        level_two.ProductsLevel_3.length > 0,
                                      selected:
                                        _vm.selectedLevelTwo
                                          .ProductLevelTwoID ===
                                        level_two.ProductLevelTwoID,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectLevelTwo(level_two)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(level_two.Name)),
                                    ]),
                                    _c("div", { staticClass: "controls" }, [
                                      _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEditLevelTwo(
                                                    level_two
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("edit")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("editProductTooltip")
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleConfigHasProd_2(
                                                    level_two
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("add")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "editProductLevelTooltip"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDeleteConfigProd(
                                                    level_two,
                                                    2
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("delete")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "deleteFromMasterTooltip"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            _c("div", {
                              staticClass: "md-layout-item md-size-50 center",
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "md-layout-item md-size-50 center",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-primary md-round",
                                    attrs: {
                                      disabled: !_vm.levelOneIsSelected,
                                    },
                                    on: { click: _vm.askChooseLevelTwo },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("add")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]),
                      _c("template", { slot: "tab-pane-3" }, [
                        _c(
                          "div",
                          { staticClass: "md-layout" },
                          [
                            !_vm.selectedLevelTwo.Name
                              ? _c(
                                  "div",
                                  { staticClass: "md-layout-item md-size-100" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("noProductLevelTwoSelected")
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._l(
                              _vm.selectedLevelTwo.ProductsLevel_3,
                              function (level_three) {
                                return _c(
                                  "div",
                                  {
                                    key: level_three.ProductLevelThreeID,
                                    staticClass: "level-three-container",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(level_three.Name)),
                                    ]),
                                    _c("div", { staticClass: "controls" }, [
                                      _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEditLevelThree(
                                                    level_three
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("edit")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("editProductTooltip")
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleConfigHasProd_3(
                                                    level_three
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("add")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "editProductLevelTooltip"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDeleteConfigProd(
                                                    level_three,
                                                    3
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("delete")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "deleteFromMasterTooltip"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            _c("div", {
                              staticClass: "md-layout-item md-size-50 center",
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "md-layout-item md-size-50 center",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-primary md-round",
                                    attrs: {
                                      disabled: !_vm.levelTwoIsSelected,
                                    },
                                    on: { click: _vm.askChooseLevelThree },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("add")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "no-model" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }