var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.renderKey, staticClass: "edit-product-level-content" },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              return [
                _c(
                  "form",
                  [
                    _c("loader", { attrs: { loader: _vm.loader } }),
                    _c("div", { staticClass: "md-layout" }, [
                      _vm.productLevel !== 3 &&
                      _vm.productLevelSelection.length === 0
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("productLevelOneID"))),
                                  ]),
                                  _c("md-input", {
                                    attrs: { type: "text", disabled: "" },
                                    model: {
                                      value:
                                        _vm.configurationHasProductLevel
                                          .ProductLevelOneID,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configurationHasProductLevel,
                                          "ProductLevelOneID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configurationHasProductLevel.ProductLevelOneID",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.productLevel !== 1 &&
                      _vm.productLevelSelection.length === 0
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("productLevelTwoID"))),
                                  ]),
                                  _c("md-input", {
                                    attrs: { type: "text", disabled: "" },
                                    model: {
                                      value:
                                        _vm.configurationHasProductLevel
                                          .ProductLevelTwoID,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configurationHasProductLevel,
                                          "ProductLevelTwoID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configurationHasProductLevel.ProductLevelTwoID",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.productLevel === 3 &&
                      _vm.productLevelSelection.length === 0
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("productLevelThreeID"))
                                    ),
                                  ]),
                                  _c("md-input", {
                                    attrs: { type: "text", disabled: "" },
                                    model: {
                                      value:
                                        _vm.configurationHasProductLevel
                                          .ProductLevelThreeID,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configurationHasProductLevel,
                                          "ProductLevelThreeID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configurationHasProductLevel.ProductLevelThreeID",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-100" },
                        [
                          _c(
                            "md-field",
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("configurationID"))),
                              ]),
                              _c("md-input", {
                                attrs: { type: "text" },
                                model: {
                                  value:
                                    _vm.configurationHasProductLevel
                                      .ConfigurationID,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.configurationHasProductLevel,
                                      "ConfigurationID",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "configurationHasProductLevel.ConfigurationID",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-100" },
                        [
                          _c(
                            "md-field",
                            [
                              _c("label", [_vm._v(_vm._s(_vm.$t("count")))]),
                              _c("md-input", {
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.configurationHasProductLevel.Count,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.configurationHasProductLevel,
                                      "Count",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "configurationHasProductLevel.Count",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-100" },
                        [
                          _c(
                            "md-field",
                            { staticClass: "md-has-value date-field" },
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("startDate"))),
                              ]),
                              _c("md-input", {
                                attrs: { type: "date" },
                                model: {
                                  value:
                                    _vm.configurationHasProductLevel.StartDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.configurationHasProductLevel,
                                      "StartDate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "configurationHasProductLevel.StartDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-100" },
                        [
                          _c(
                            "md-field",
                            { staticClass: "md-has-value date-field" },
                            [
                              _c("label", [_vm._v(_vm._s(_vm.$t("limited")))]),
                              _c("md-input", {
                                attrs: { type: "date" },
                                model: {
                                  value:
                                    _vm.configurationHasProductLevel.Limited,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.configurationHasProductLevel,
                                      "Limited",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "configurationHasProductLevel.Limited",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.productLevel === 1
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c(
                                    "md-checkbox",
                                    {
                                      attrs: {
                                        name: "test",
                                        id: "test",
                                        value: "0",
                                      },
                                      model: {
                                        value: _vm.valTest50,
                                        callback: function ($$v) {
                                          _vm.valTest50 = $$v
                                        },
                                        expression: "valTest50",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("test50")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.productLevel === 1
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c(
                                    "md-checkbox",
                                    {
                                      attrs: {
                                        name: "student",
                                        id: "student",
                                        value: "0",
                                      },
                                      model: {
                                        value: _vm.valStudent,
                                        callback: function ($$v) {
                                          _vm.valStudent = $$v
                                        },
                                        expression: "valStudent",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("student")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.productLevel === 1
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c(
                                    "md-checkbox",
                                    {
                                      attrs: {
                                        name: "uncounted",
                                        id: "uncounted",
                                        value: "0",
                                      },
                                      model: {
                                        value: _vm.valUncounted,
                                        callback: function ($$v) {
                                          _vm.valUncounted = $$v
                                        },
                                        expression: "valUncounted",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("uncounted")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.productLevel === 1
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c(
                                    "md-checkbox",
                                    {
                                      attrs: {
                                        name: "editModules",
                                        id: "editModules",
                                        value: "0",
                                      },
                                      model: {
                                        value: _vm.valEditModules,
                                        callback: function ($$v) {
                                          _vm.valEditModules = $$v
                                        },
                                        expression: "valEditModules",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("editModules")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.productLevel === 1
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c(
                                    "md-checkbox",
                                    {
                                      attrs: {
                                        name: "connect",
                                        id: "connect",
                                        value: "0",
                                      },
                                      model: {
                                        value: _vm.valConnect,
                                        callback: function ($$v) {
                                          _vm.valConnect = $$v
                                        },
                                        expression: "valConnect",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("connect")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.productLevelSelection.length === 0
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("sorting"))),
                                  ]),
                                  _c("md-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value:
                                        _vm.configurationHasProductLevel
                                          .Sorting,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configurationHasProductLevel,
                                          "Sorting",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configurationHasProductLevel.Sorting",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "md-layout" }, [
                        _c(
                          "div",
                          { staticClass: "md-layout-item md-size-50" },
                          [
                            !_vm.editProduct
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass: "md-round md-primary",
                                    on: { click: _vm.askCreate },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("create")))]
                                )
                              : _c(
                                  "md-button",
                                  {
                                    staticClass: "md-round md-primary",
                                    on: { click: _vm.askUpdate },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("save")))]
                                ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "md-layout-item md-size-50" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-round md-simple",
                                on: { click: _vm.closeModal },
                              },
                              [_vm._v(_vm._s(_vm.$t("cancel")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }