var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var handleSubmit = ref.handleSubmit
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.submit)
                      },
                    },
                  },
                  [
                    _c("loader", { attrs: { loader: _vm.loader } }),
                    _c(
                      "md-card",
                      { staticClass: "card-content" },
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass: "md-card-header-icon",
                            class: _vm.getClass(_vm.headerColor),
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("perm_identity")])],
                              1
                            ),
                            _c("h4", { staticClass: "title" }, [
                              _vm._v(" " + _vm._s(_vm.$t("contactData")) + " "),
                            ]),
                            _c("h3", [
                              _vm._v(_vm._s(_vm.$t("editContactText"))),
                            ]),
                          ]
                        ),
                        _c("md-card-content", [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("contactId"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text", disabled: "" },
                                      model: {
                                        value: _vm.contact.ID,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, "ID", $$v)
                                        },
                                        expression: "contact.ID",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("customerID"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text", disabled: "" },
                                      model: {
                                        value: _vm.contact.CompanyID,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.contact,
                                            "CompanyID",
                                            $$v
                                          )
                                        },
                                        expression: "contact.CompanyID",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "salutation" } },
                                      [_vm._v(_vm._s(_vm.$t("salutation")))]
                                    ),
                                    _c(
                                      "md-select",
                                      {
                                        attrs: {
                                          name: "salutation",
                                          id: "salutation",
                                        },
                                        model: {
                                          value: _vm.contact.Salutation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "Salutation",
                                              $$v
                                            )
                                          },
                                          expression: "contact.Salutation",
                                        },
                                      },
                                      [
                                        _c(
                                          "md-option",
                                          { attrs: { value: "Herr" } },
                                          [_vm._v(_vm._s(_vm.$t("mr")))]
                                        ),
                                        _c(
                                          "md-option",
                                          { attrs: { value: "Frau" } },
                                          [_vm._v(_vm._s(_vm.$t("ms")))]
                                        ),
                                        _c(
                                          "md-option",
                                          { attrs: { value: "NoSalutation" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("noSalutaition"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("title"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.contact.Title,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, "Title", $$v)
                                        },
                                        expression: "contact.Title",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "firstname",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var passed = ref.passed
                                              var failed = ref.failed
                                              return [
                                                _c(
                                                  "md-field",
                                                  {
                                                    class: [
                                                      { "md-error": failed },
                                                      { "md-valid": passed },
                                                    ],
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("firstName")
                                                        )
                                                      ),
                                                    ]),
                                                    _c("md-input", {
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value:
                                                          _vm.contact.FirstName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contact,
                                                            "FirstName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.FirstName",
                                                      },
                                                    }),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: failed,
                                                                expression:
                                                                  "failed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [_vm._v("close")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: passed,
                                                                expression:
                                                                  "passed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "success",
                                                          },
                                                          [_vm._v("done")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "lastname",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var passed = ref.passed
                                              var failed = ref.failed
                                              return [
                                                _c(
                                                  "md-field",
                                                  {
                                                    class: [
                                                      { "md-error": failed },
                                                      { "md-valid": passed },
                                                    ],
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("lastName")
                                                        )
                                                      ),
                                                    ]),
                                                    _c("md-input", {
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value:
                                                          _vm.contact.LastName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contact,
                                                            "LastName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.LastName",
                                                      },
                                                    }),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: failed,
                                                                expression:
                                                                  "failed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [_vm._v("close")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: passed,
                                                                expression:
                                                                  "passed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "success",
                                                          },
                                                          [_vm._v("done")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _vm.adminRole && _vm.userFound
                                  ? _c(
                                      "md-field",
                                      { key: _vm.rendeKey },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "role" } },
                                          [_vm._v(_vm._s(_vm.$t("role")))]
                                        ),
                                        _c(
                                          "md-select",
                                          {
                                            attrs: { name: "role", id: "role" },
                                            model: {
                                              value: _vm.mdUser.MDUserRoleID,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mdUser,
                                                  "MDUserRoleID",
                                                  $$v
                                                )
                                              },
                                              expression: "mdUser.MDUserRoleID",
                                            },
                                          },
                                          _vm._l(
                                            _vm.userRoles,
                                            function (role) {
                                              return _c(
                                                "md-option",
                                                {
                                                  key: role.ID,
                                                  attrs: { value: role.ID },
                                                },
                                                [_vm._v(_vm._s(role.Name))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("phoneNumber"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.contact.Phone,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, "Phone", $$v)
                                        },
                                        expression: "contact.Phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "email",
                                    rules: "required|email",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var passed = ref.passed
                                          var failed = ref.failed
                                          return [
                                            _c(
                                              "md-field",
                                              {
                                                class: [
                                                  { "md-error": failed },
                                                  { "md-valid": passed },
                                                ],
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("email"))
                                                  ),
                                                ]),
                                                _c("md-input", {
                                                  attrs: { type: "email" },
                                                  model: {
                                                    value: _vm.contact.Email,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contact,
                                                        "Email",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "contact.Email",
                                                  },
                                                }),
                                                _c(
                                                  "slide-y-down-transition",
                                                  [
                                                    _c(
                                                      "md-icon",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: failed,
                                                            expression:
                                                              "failed",
                                                          },
                                                        ],
                                                        staticClass: "error",
                                                      },
                                                      [_vm._v("close")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "slide-y-down-transition",
                                                  [
                                                    _c(
                                                      "md-icon",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: passed,
                                                            expression:
                                                              "passed",
                                                          },
                                                        ],
                                                        staticClass: "success",
                                                      },
                                                      [_vm._v("done")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("position"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.contact.Position,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, "Position", $$v)
                                        },
                                        expression: "contact.Position",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c(
                                      "md-checkbox",
                                      {
                                        staticClass: "imo-checkbox",
                                        model: {
                                          value: _vm.contact.MainContact,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "MainContact",
                                              $$v
                                            )
                                          },
                                          expression: "contact.MainContact",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("mainContact")) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c("select-country", {
                                  attrs: {
                                    value: _vm.contact.Language.toUpperCase(),
                                  },
                                  on: { changeCountry: _vm.handleCountry },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("createdBy"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text", disabled: "" },
                                      model: {
                                        value: _vm.contact.CreatedBy,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.contact,
                                            "CreatedBy",
                                            $$v
                                          )
                                        },
                                        expression: "contact.CreatedBy",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("createdOn"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text", disabled: "" },
                                      model: {
                                        value: _vm.contact.Created,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, "Created", $$v)
                                        },
                                        expression: "contact.Created",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("modifiedBy"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text", disabled: "" },
                                      model: {
                                        value: _vm.contact.ModifiedBy,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.contact,
                                            "ModifiedBy",
                                            $$v
                                          )
                                        },
                                        expression: "contact.ModifiedBy",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("modifiedOn"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text", disabled: "" },
                                      model: {
                                        value: _vm.contact.Modified,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, "Modified", $$v)
                                        },
                                        expression: "contact.Modified",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-size-100 text-right",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-raised md-primary mt-4",
                                    attrs: { id: "submit", disabled: invalid },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateContact()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("save")) + " ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }