var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "choice",
      class: { active: _vm.checked },
      attrs: { "data-toggle": "wizard-checkbox" },
      on: { click: _vm.updateValue },
    },
    [
      _c("input", {
        attrs: { type: "checkbox", name: _vm.name, disabled: _vm.disabled },
        domProps: { checked: _vm.checked },
      }),
      _c(
        "div",
        { staticClass: "icon" },
        [
          _vm._t("icon", function () {
            return [_c("i", { class: _vm.icon })]
          }),
        ],
        2
      ),
      _vm._t("title", function () {
        return [_c("h6", [_vm._v(_vm._s(_vm.title))])]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }