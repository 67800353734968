import IconCheckbox from './Inputs/IconCheckbox.vue'
import Badge from './Badge'

import DropDown from './Dropdown.vue'
// Modals
import Modal from './Modal.vue'

// Tables
import GlobalSalesTable from './Tables/GlobalSalesTable.vue'

import SignupCard from './Cards/SignupCard.vue'
import LockCard from './Cards/LockCard.vue'
import LoginCard from './Cards/LoginCard.vue'
import StatsCard from './Cards/StatsCard.vue'
import ChartCard from './Cards/ChartCard.vue'
import TestimonialCard from './Cards/TestimonialCard.vue'
import GlobalSalesCard from './Cards/GlobalSalesCard.vue'
import NavTabsCard from './Cards/NavTabsCard.vue'
import ProductCard from './Cards/ProductCard.vue'

import TimeLine from './Timeline/TimeLine.vue'
import TimeLineItem from './Timeline/TimeLineItem.vue'
import Tabs from './Tabs.vue'
import Collapse from './Collapse.vue'
import Slider from './Slider.vue'
import SimpleWizard from './Wizard/Wizard.vue'
import WizardTab from './Wizard/WizardTab.vue'
import AsyncWorldMap from './WorldMap/AsyncWorldMap.vue'

import Pagination from './Pagination.vue'

import SidebarPlugin from './SidebarPlugin'

import AnimatedNumber from './AnimatedNumber'

export {
  IconCheckbox,
  Badge,
  Modal,
  Pagination,
  GlobalSalesTable,
  LockCard,
  SignupCard,
  TestimonialCard,
  StatsCard,
  LoginCard,
  ChartCard,
  GlobalSalesCard,
  NavTabsCard,
  ProductCard,
  DropDown,
  SidebarPlugin,
  TimeLine,
  TimeLineItem,
  Tabs,
  Slider,
  SimpleWizard,
  WizardTab,
  AnimatedNumber,
  Collapse,
  AsyncWorldMap,
}
