var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "edit-profile" },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _c(
        "md-card",
        { staticClass: "card-content" },
        [
          _c(
            "md-card-header",
            {
              staticClass: "md-card-header-icon header-style",
              class: _vm.getClass(_vm.headerColor),
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("perm_identity")])],
                  1
                ),
                _c("h4", { staticClass: "title user-profile-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("profileTitle")) + " "),
                ]),
                _c("h3", [_vm._v(_vm._s(_vm.$t("profileText")))]),
              ]),
              _c("div", { staticClass: "md-layout avatar" }, [
                _c("div", { staticClass: "picture-container" }, [
                  _c("div", { staticClass: "picture" }, [
                    !_vm.image
                      ? _c("div", [
                          _c("img", {
                            staticClass: "picture-src",
                            attrs: { src: _vm.avatar, title: "" },
                          }),
                        ])
                      : _c("div", [
                          _c("img", {
                            staticClass: "img-style",
                            attrs: { src: _vm.image },
                          }),
                        ]),
                  ]),
                  _c(
                    "h6",
                    {
                      staticClass: "description",
                      on: {
                        click: function ($event) {
                          return _vm.$vm2.open("modal-1")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("choosePicture")) + " ")]
                  ),
                ]),
              ]),
            ]
          ),
          _c("md-card-content", [
            _c("div", { staticClass: "md-layout" }, [
              !_vm.isStudent
                ? _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-small-size-100 md-size-50",
                    },
                    [
                      _c(
                        "md-field",
                        [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("customerNumber"))),
                          ]),
                          _c("md-input", {
                            attrs: { disabled: "", type: "text" },
                            model: {
                              value: _vm.companyNumber,
                              callback: function ($$v) {
                                _vm.companyNumber = $$v
                              },
                              expression: "companyNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c("ValidationProvider", {
                    attrs: { name: "email", rules: "required|email" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var passed = ref.passed
                          var failed = ref.failed
                          return [
                            _c(
                              "md-field",
                              {
                                class: [
                                  { "md-error": failed },
                                  { "md-valid": passed },
                                ],
                              },
                              [
                                _c("label", [_vm._v(_vm._s(_vm.$t("email")))]),
                                _c("md-input", {
                                  attrs: {
                                    type: "email",
                                    disabled: "disabled",
                                  },
                                  model: {
                                    value: _vm.email,
                                    callback: function ($$v) {
                                      _vm.email = $$v
                                    },
                                    expression: "email",
                                  },
                                }),
                                _c(
                                  "slide-y-down-transition",
                                  [
                                    _c(
                                      "md-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: failed,
                                            expression: "failed",
                                          },
                                        ],
                                        staticClass: "error",
                                      },
                                      [_vm._v("close")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "slide-y-down-transition",
                                  [
                                    _c(
                                      "md-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: passed,
                                            expression: "passed",
                                          },
                                        ],
                                        staticClass: "success",
                                      },
                                      [_vm._v("done")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", { attrs: { for: "salutation" } }, [
                        _vm._v(_vm._s(_vm.$t("salutation"))),
                      ]),
                      _c(
                        "md-select",
                        {
                          attrs: { name: "salutation", id: "salutation" },
                          model: {
                            value: _vm.salutation,
                            callback: function ($$v) {
                              _vm.salutation = $$v
                            },
                            expression: "salutation",
                          },
                        },
                        [
                          _c("md-option", { attrs: { value: "Herr" } }, [
                            _vm._v(_vm._s(_vm.$t("mr"))),
                          ]),
                          _c("md-option", { attrs: { value: "Frau" } }, [
                            _vm._v(_vm._s(_vm.$t("ms"))),
                          ]),
                          _c(
                            "md-option",
                            { attrs: { value: "NoSalutation" } },
                            [_vm._v(_vm._s(_vm.$t("noSalutaition")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("firstName")))]),
                      _c("md-input", {
                        attrs: { type: "text", id: "firstname" },
                        model: {
                          value: _vm.firstname,
                          callback: function ($$v) {
                            _vm.firstname = $$v
                          },
                          expression: "firstname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("lastName")))]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.lastname,
                          callback: function ($$v) {
                            _vm.lastname = $$v
                          },
                          expression: "lastname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("street")))]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.street,
                          callback: function ($$v) {
                            _vm.street = $$v
                          },
                          expression: "street",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("houseNumber")))]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.housenumber,
                          callback: function ($$v) {
                            _vm.housenumber = $$v
                          },
                          expression: "housenumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("city")))]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.city,
                          callback: function ($$v) {
                            _vm.city = $$v
                          },
                          expression: "city",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [_c("select-country", { attrs: { value: _vm.country } })],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c(
                    "div",
                    [
                      _c("ValidationProvider", {
                        attrs: { name: "zipcode", rules: "required|numeric" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var passed = ref.passed
                              var failed = ref.failed
                              return [
                                _c(
                                  "md-field",
                                  {
                                    class: [
                                      { "md-error": failed },
                                      { "md-valid": passed },
                                    ],
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("zipCode")) + " "
                                      ),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.zipcode,
                                        callback: function ($$v) {
                                          _vm.zipcode = $$v
                                        },
                                        expression: "zipcode",
                                      },
                                    }),
                                    _c(
                                      "slide-y-down-transition",
                                      [
                                        _c(
                                          "md-icon",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: failed,
                                                expression: "failed",
                                              },
                                            ],
                                            staticClass: "error",
                                          },
                                          [_vm._v("close")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "slide-y-down-transition",
                                      [
                                        _c(
                                          "md-icon",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: passed,
                                                expression: "passed",
                                              },
                                            ],
                                            staticClass: "success",
                                          },
                                          [_vm._v("done")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c(
                    "md-field",
                    { staticClass: "switch-field" },
                    [
                      _c(
                        "md-switch",
                        {
                          staticClass: "field-switch",
                          on: { change: _vm.toggleTwoFA },
                          model: {
                            value: _vm.md_user.TwoFactorEnabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.md_user, "TwoFactorEnabled", $$v)
                            },
                            expression: "md_user.TwoFactorEnabled",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("twoFAuthentication")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isStudent
                ? _c("div", {
                    staticClass: "md-layout-item md-small-size-100 md-size-50",
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "update-profile-button md-layout-item md-size-100 text-right",
                },
                [
                  _c(
                    "div",
                    {},
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-raised md-primary mt-4 md-round",
                          attrs: {
                            disabled: !_vm.allow(
                              "{81a28fc4-2cb1-4176-883f-4bee441fd5bb}"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateProfile()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text-center" }),
                  _c("form", { attrs: { enctype: "multipart/form-data" } }),
                  _c("div"),
                  _c(
                    "modal-vue",
                    {
                      attrs: { name: "modal-1", noFooter: "", noHeader: "" },
                      on: {
                        "on-close": function ($event) {
                          return _vm.$vm2.close("modal-1")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "avatar-modal-title" }, [
                            _vm._v(_vm._s(_vm.$t("avatarModal"))),
                          ]),
                          _c("div", { staticClass: "upload-instruction" }, [
                            _vm._v(_vm._s(_vm.$t("uploadOrSelect"))),
                          ]),
                          _c("vue-avatar", {
                            ref: "vueavatar",
                            staticClass: "upload-avatar",
                            attrs: {
                              width: 200,
                              height: 200,
                              scale: +_vm.sliders.simple,
                              border: 0,
                              placeholderSvg: _vm.defaultSvg,
                            },
                          }),
                          _c("slider", {
                            attrs: { range: { min: 1, max: 10 } },
                            model: {
                              value: _vm.sliders.simple,
                              callback: function ($$v) {
                                _vm.$set(_vm.sliders, "simple", $$v)
                              },
                              expression: "sliders.simple",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "avatar-buttons" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-round",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$vm2.close("modal-1")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("cancel")))]
                              ),
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-primary md-round",
                                  on: {
                                    click: function ($event) {
                                      _vm.saveNewAvatar()
                                      _vm.$vm2.close("modal-1")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("save")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }