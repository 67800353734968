var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.renderkey, staticClass: "products-list-content" },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _vm.showModal
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "add-product-modal" },
              on: { close: _vm.hideModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h3", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.$t("chooseProduct"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "md-layout modal-content" },
                          _vm._l(_vm.productsToAdd, function (p, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "md-layout-item md-size-100",
                              },
                              [
                                _c(
                                  "md-field",
                                  { staticClass: "modal-product list-row" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "modal-span",
                                        attrs: { type: "text", id: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAdd(p)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(p.Title))]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                4161576821
              ),
            },
            [_c("template", { slot: "footer" })],
            2
          )
        : _vm._e(),
      _vm.showInfoModal
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "add-info-modal" },
              on: { close: _vm.hideModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h3", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.$t("productCommentary"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "md-layout modal-content" }, [
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", { attrs: { for: "info" } }, [
                                    _vm._v(_vm._s(_vm.$t("comment"))),
                                  ]),
                                  _c("md-input", {
                                    attrs: { name: "info", type: "text" },
                                    model: {
                                      value: _vm.productInfo,
                                      callback: function ($$v) {
                                        _vm.productInfo = $$v
                                      },
                                      expression: "productInfo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-50" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-simple md-round",
                                  on: { click: _vm.hideModal },
                                },
                                [_vm._v(_vm._s(_vm.$t("cancel")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-50" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-primary md-round",
                                  on: {
                                    click: function ($event) {
                                      return _vm.postAdd(_vm.toPostProduct)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("confirm")))]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                75395022
              ),
            },
            [_c("template", { slot: "footer" })],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "title-div" }, [
        _vm.companyProductsPage || _vm.allProductsPage || _vm.contactProducts
          ? _c("h4", { staticClass: "company-name-title" }, [
              _vm._v(" " + _vm._s(this.cardTitle) + " "),
            ])
          : _c("h4", { staticClass: "my-company-title" }, [
              _vm._v(_vm._s(_vm.$t("myproducts"))),
            ]),
      ]),
      _c(
        "div",
        { staticClass: "display-products" },
        [
          _vm._l(_vm.selectedProducts, function (product) {
            return _c("div", { key: product.ID + _vm.getRndNo() }, [
              _c(
                "div",
                { staticClass: "md-layout-item" },
                [
                  _c(
                    "product-card",
                    {
                      attrs: { "header-animation": "true" },
                      on: {
                        "delete-event": function ($event) {
                          _vm.companyProductsPage
                            ? _vm.handleDelete(product)
                            : null
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-wrap",
                          attrs: { slot: "imageHeader" },
                          on: {
                            click: function ($event) {
                              return _vm.goToProductPage(product)
                            },
                          },
                          slot: "imageHeader",
                        },
                        [
                          _c("img", {
                            staticClass: "img",
                            attrs: {
                              src: _vm.productImages[product.ID],
                              alt: product.Image,
                            },
                          }),
                        ]
                      ),
                      _c(
                        "h4",
                        {
                          staticClass: "title",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goToProductPage(product)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(product[_vm.$t("productTitle")]) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "card-description subtitle",
                          attrs: { slot: "description" },
                          slot: "description",
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(product[_vm.$t("productSubtitle")])),
                          ]),
                          product.Info
                            ? _c("div", [_vm._v(_vm._s(product.Info))])
                            : _vm._e(),
                        ]
                      ),
                      _vm.companyProductsPage &&
                      _vm.allow("{c452b678-30a6-48af-8fee-087040a6f594}")
                        ? _c(
                            "template",
                            { slot: "third-button" },
                            [
                              _c("md-icon", [_vm._v("close")]),
                              _c(
                                "md-tooltip",
                                { attrs: { "md-direction": "bottom" } },
                                [_vm._v(_vm._s(_vm.$t("remove")))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ])
          }),
          !_vm.studentRole &&
          _vm.allow("{6f2004e5-c1e2-4998-a660-1838cfda8c8e}")
            ? _c("div", { attrs: { id: "add-prod" } }, [
                _c("div", { staticClass: "md-layout" }, [
                  _c("div", { staticClass: "md-layout-item md-size-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "add-icon-div",
                        on: { click: _vm.toggleModal },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "add-prod-icon animated-icon" },
                          [_vm._v("add_circle")]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "add-icon-text" }, [
                      _c("span", [_vm._v(_vm._s(_vm.$t("addNewProduct")))]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }