var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-field",
    { staticClass: "country" },
    [
      _c("label", { attrs: { for: "country" } }, [
        _vm._v(_vm._s(_vm.$t("country"))),
      ]),
      _c(
        "md-select",
        {
          attrs: { name: "country", id: "country" },
          on: {
            click: function ($event) {
              return _vm.addCustomClass()
            },
          },
          model: {
            value: _vm.country,
            callback: function ($$v) {
              _vm.country = $$v
            },
            expression: "country",
          },
        },
        _vm._l(this.countries, function (c) {
          return _c(
            "md-option",
            {
              key: c.value,
              staticClass: "country-option",
              attrs: { value: c.value },
            },
            [_vm._v(_vm._s(c.label))]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }