var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout text-center", attrs: { id: "login-tile" } },
    [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100",
        },
        [
          _c("loader", { attrs: { loader: _vm.loader } }),
          _c("login-card", { attrs: { "header-color": "primary" } }, [
            _c("img", {
              staticClass: "img-title",
              attrs: {
                slot: "title",
                src: _vm.MyMdesignLogo,
                alt: "MyMdesignLogo",
              },
              slot: "title",
            }),
            _c(
              "div",
              { attrs: { slot: "inputs" }, slot: "inputs" },
              [
                _c("ValidationObserver", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var handleSubmit = ref.handleSubmit
                        var invalid = ref.invalid
                        return [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return handleSubmit(_vm.submit)
                                },
                              },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "user",
                                  rules: "required|email",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var passed = ref.passed
                                        var failed = ref.failed
                                        return [
                                          _c(
                                            "md-field",
                                            {
                                              class: [
                                                { "md-error": failed },
                                                { "md-valid": passed },
                                              ],
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(_vm._s(_vm.$t("email"))),
                                              ]),
                                              _c("md-input", {
                                                attrs: {
                                                  type: "text",
                                                  id: "user",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeType()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.user,
                                                  callback: function ($$v) {
                                                    _vm.user = $$v
                                                  },
                                                  expression: "user",
                                                },
                                              }),
                                              _c(
                                                "slide-y-down-transition",
                                                [
                                                  _c(
                                                    "md-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: failed,
                                                          expression: "failed",
                                                        },
                                                      ],
                                                      staticClass: "error",
                                                    },
                                                    [_vm._v("close")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "slide-y-down-transition",
                                                [
                                                  _c(
                                                    "md-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: passed,
                                                          expression: "passed",
                                                        },
                                                      ],
                                                      staticClass: "success",
                                                    },
                                                    [_vm._v("done")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "pass",
                                  rules: {
                                    required: true,
                                    regex: _vm.regExValid,
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var passed = ref.passed
                                        var failed = ref.failed
                                        return [
                                          _c(
                                            "md-field",
                                            {
                                              class: [
                                                { "md-error": failed },
                                                { "md-valid": passed },
                                              ],
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("password"))
                                                ),
                                              ]),
                                              _c("md-input", {
                                                attrs: {
                                                  type: "password",
                                                  id: "password",
                                                },
                                                on: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.getContact()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.password,
                                                  callback: function ($$v) {
                                                    _vm.password = $$v
                                                  },
                                                  expression: "password",
                                                },
                                              }),
                                              _c(
                                                "slide-y-down-transition",
                                                [
                                                  _c(
                                                    "md-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: failed,
                                                          expression: "failed",
                                                        },
                                                      ],
                                                      staticClass: "error",
                                                    },
                                                    [_vm._v("close")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "slide-y-down-transition",
                                                [
                                                  _c(
                                                    "md-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: passed,
                                                          expression: "passed",
                                                        },
                                                      ],
                                                      staticClass: "success",
                                                    },
                                                    [_vm._v("done")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "remaining-attempts" }, [
                            _vm.wrongPw
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("remainingAttempts") +
                                        _vm.userStore.lockout.Attempts
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm.showResendMail
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-size-100 just-center resend-mail-content",
                                },
                                [
                                  _c("div", { staticClass: "resend-mail" }, [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("resendMail"))),
                                      _c("br"),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("clickHere")) + " "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          on: { click: _vm.resendConfirmMail },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("resendMailLink"))
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "forgot-password" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push({
                                      name: "Forgot Password",
                                    })
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("forgotPassword")))]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "cta-style" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-simple md-round",
                                  attrs: {
                                    slot: "footer",
                                    id: "registration-button",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        name: "Registration",
                                      })
                                    },
                                  },
                                  slot: "footer",
                                },
                                [
                                  _c("md-icon", [_vm._v("how_to_reg")]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("registration")) + " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-primary md-round",
                                  attrs: {
                                    slot: "footer",
                                    disabled: invalid,
                                    id: "Submit",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getContact()
                                    },
                                  },
                                  slot: "footer",
                                },
                                [
                                  _c("md-icon", [_vm._v("login")]),
                                  _vm._v(" " + _vm._s(_vm.$t("letsgo")) + " "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }