var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-toolbar",
    {
      staticClass: "md-transparent",
      class: {
        "md-toolbar-absolute md-white md-fixed-top":
          _vm.$route.meta.navbarAbsolute,
      },
      attrs: { "md-elevation": "0" },
    },
    [
      _c(
        "div",
        { staticClass: "md-toolbar-row" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-just-icon md-round md-simple md-toolbar-toggle",
              class: {
                toggled: _vm.$sidebar.showSidebar,
                "show-mobile-menu-button": _vm.$sidebar.showSidebar,
              },
              on: { click: _vm.toggleSidebar },
            },
            [
              _c("span", { staticClass: "icon-bar" }),
              _c("span", { staticClass: "icon-bar" }),
              _c("span", { staticClass: "icon-bar" }),
            ]
          ),
          _c("div", { staticClass: "mymdesign-logo" }, [
            _c("img", {
              attrs: { src: _vm.myMdesignLogo, alt: "company_small_logo" },
            }),
          ]),
          _c("div", { staticClass: "md-collapse" }, [
            _c(
              "div",
              { staticClass: "theme-switch" },
              [
                _c("div", { staticClass: "theme-color" }, [
                  _vm._v(_vm._s(_vm.$t("darkTheme"))),
                ]),
                _c("md-switch", {
                  model: {
                    value: _vm.isWhiteTheme,
                    callback: function ($$v) {
                      _vm.isWhiteTheme = $$v
                    },
                    expression: "isWhiteTheme",
                  },
                }),
                _c("div", { staticClass: "theme-color" }, [
                  _vm._v(_vm._s(_vm.$t("lightTheme"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "lang-select",
                on: {
                  mouseover: function ($event) {
                    return _vm.openLangDropDown()
                  },
                },
              },
              [_c("lang-select", { staticClass: "navbar-lang" })],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }