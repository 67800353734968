var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loader,
          expression: "loader",
        },
      ],
      staticClass: "loader",
      attrs: { id: "loader" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }