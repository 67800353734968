var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "md-layout md-size-33 md-medium-size-100 md-small-size-100 md-xsmall-size-100",
    },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      !_vm.loader
        ? _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-default" },
                [
                  _c("h2", { staticClass: "title text-center" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("confirmRegistrationTitle")) + " "
                    ),
                  ]),
                ]
              ),
              _c("md-card-content", { staticClass: "sucess-text" }, [
                _vm._v(" " + _vm._s(_vm.$t("successfulRegistration")) + " "),
                _c("a", { attrs: { href: "/login" } }, [
                  _vm._v(_vm._s(_vm.$t("link"))),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }