var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "protocol-content" },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _c(
        "md-card",
        { staticClass: "md-card-configuration-protocol" },
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-primary" },
            [
              _c(
                "div",
                { staticClass: "card-icon", on: { click: _vm.postModels } },
                [_c("md-icon", [_vm._v("content_paste")])],
                1
              ),
              _c("h4", {}, [_vm._v(_vm._s(_vm.$t("protocol")))]),
            ]
          ),
          _c("md-card-content", [
            _c(
              "div",
              { staticClass: "controls" },
              [
                _c(
                  "md-switch",
                  {
                    model: {
                      value: _vm.showAll,
                      callback: function ($$v) {
                        _vm.showAll = $$v
                      },
                      expression: "showAll",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("showAll")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout protocol-table" },
              [
                _c(
                  "div",
                  { staticClass: "md-layout md-layout-item md-size-100" },
                  [
                    _c("div", { staticClass: "md-layout-item md-size-35" }, [
                      _vm._v(_vm._s(_vm.$t("Name"))),
                    ]),
                    _c("div", { staticClass: "md-layout-item md-size-25" }, [
                      _vm._v("ID"),
                    ]),
                    _c("div", { staticClass: "md-layout-item md-size-10" }, [
                      _vm._v(_vm._s(_vm.$t("count"))),
                    ]),
                    _c("div", { staticClass: "md-layout-item md-size-10" }, [
                      _vm._v(_vm._s(_vm.$t("endDate"))),
                    ]),
                    _c("div", { staticClass: "md-layout-item md-size-20" }),
                  ]
                ),
                _vm._l(_vm.licSelected, function (p) {
                  return _c(
                    "div",
                    {
                      key: p.ConfigurationHasProductLevelOne.ID,
                      staticClass: "md-layout md-layout-item md-size-100",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout md-layout-item md-size-100 protocol-header",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-35" },
                            [_vm._v(_vm._s(p.ProductLevelOne.Name))]
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-25" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (p.ProductLevelOne.LicName !== ""
                                      ? p.ProductLevelOne.LicName
                                      : p.ProductLevelOne.LicNameParent) +
                                      _vm.version
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-10" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    p.ConfigurationHasProductLevelOne.Count ===
                                      9999
                                      ? "uncounted"
                                      : p.ConfigurationHasProductLevelOne.Count
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-20" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    p.ConfigurationHasProductLevelOne
                                      .Limited === "9999-12-31"
                                      ? "permanent"
                                      : p.ConfigurationHasProductLevelOne.Limited.split(
                                          "T"
                                        )[0]
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-10" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "" +
                                      (p.ConfigurationHasProductLevelOne
                                        .Connect === 1
                                        ? _vm.$t("Connect")
                                        : "")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      p.ListProtocolLevelTwo.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "md-layout md-layout-item md-size-100",
                            },
                            _vm._l(p.ListProtocolLevelTwo, function (l2) {
                              return _c(
                                "div",
                                {
                                  key: l2.ConfigurationHasProductLevelTwo.ID,
                                  staticClass:
                                    "md-layout md-layout-item md-size-100",
                                  class: {
                                    "dependency ": l2.isDependency,
                                    hide: l2.isDependency && !_vm.showAll,
                                  },
                                },
                                [
                                  l2.ConfigurationHasProductLevelTwo.Count > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-35 fs-italic",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(l2.ProductLevelTwo.Name) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  l2.ConfigurationHasProductLevelTwo.Count > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-25",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                l2.ProductLevelTwo.LicName
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  l2.ConfigurationHasProductLevelTwo.Count > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-10",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                l2
                                                  .ConfigurationHasProductLevelTwo
                                                  .Count === 9999
                                                  ? "uncounted"
                                                  : l2
                                                      .ConfigurationHasProductLevelTwo
                                                      .Count
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  l2.ConfigurationHasProductLevelTwo.Count > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-20",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                l2
                                                  .ConfigurationHasProductLevelTwo
                                                  .Limited === "9999-12-31"
                                                  ? "permanent"
                                                  : l2.ConfigurationHasProductLevelTwo.Limited.split(
                                                      "T"
                                                    )[0]
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  l2.ConfigurationHasProductLevelTwo.Count > 0
                                    ? _c("div", {
                                        staticClass:
                                          "md-layout-item md-size-10",
                                      })
                                    : _vm._e(),
                                  l2.ListProtocolLevelThree.length > 0 &&
                                  l2.ConfigurationHasProductLevelTwo.Count > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout md-layout-item md-size-100 module-block",
                                        },
                                        _vm._l(
                                          l2.ListProtocolLevelThree,
                                          function (l3) {
                                            return _c(
                                              "div",
                                              {
                                                key: l3
                                                  .ConfigurationHasProductLevelThree
                                                  .ID,
                                                staticClass:
                                                  "md-layout md-layout-item md-size-100",
                                              },
                                              [
                                                l3
                                                  .ConfigurationHasProductLevelThree
                                                  .Count > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "md-layout-item md-size-35 pl-5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              "- " +
                                                                l3
                                                                  .ProductLevelThree
                                                                  .Name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                l3
                                                  .ConfigurationHasProductLevelThree
                                                  .Count > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "md-layout-item md-size-25",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              l3
                                                                .ProductLevelThree
                                                                .LicName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                l3
                                                  .ConfigurationHasProductLevelThree
                                                  .Count > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "md-layout-item md-size-10",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              l3
                                                                .ConfigurationHasProductLevelThree
                                                                .Count === 9999
                                                                ? "uncounted"
                                                                : l3
                                                                    .ConfigurationHasProductLevelThree
                                                                    .Count
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                l3
                                                  .ConfigurationHasProductLevelThree
                                                  .Count > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "md-layout-item md-size-20",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              l3
                                                                .ConfigurationHasProductLevelThree
                                                                .Limited ===
                                                                "9999-12-31"
                                                                ? "permanent"
                                                                : l3.ConfigurationHasProductLevelThree.Limited.split(
                                                                    "T"
                                                                  )[0]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                l3
                                                  .ConfigurationHasProductLevelThree
                                                  .Count > 0
                                                  ? _c("div", {
                                                      staticClass:
                                                        "md-layout-item md-size-10",
                                                    })
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._l(_vm.licDependencies, function (p) {
                  return _c(
                    "div",
                    {
                      key: p.ProductLevelOne.ID,
                      staticClass: "md-layout md-layout-item md-size-100",
                      class: {
                        "dependency ": p.isDependency,
                        hide: p.isDependency && !_vm.showAll,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout md-layout-item md-size-100 protocol-header",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-35" },
                            [
                              _vm._v(
                                _vm._s(
                                  p.ProductLevelOne.Name.split(
                                    "techDependency "
                                  )[1]
                                )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-25" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (p.ProductLevelOne.LicName !== ""
                                      ? p.ProductLevelOne.LicName
                                      : p.ProductLevelOne.LicNameParent) +
                                      _vm.version
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-10" },
                            [
                              _vm._v(
                                _vm._s(p.ConfigurationHasProductLevelOne.Count)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-20" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    p.ConfigurationHasProductLevelOne
                                      .Limited === "9999-12-31"
                                      ? "permanent"
                                      : p.ConfigurationHasProductLevelOne.Limited.split(
                                          "T"
                                        )[0]
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("div", {
                            staticClass: "md-layout-item md-size-10",
                          }),
                        ]
                      ),
                      p.ListProtocolLevelTwo.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "md-layout md-layout-item md-size-100",
                            },
                            _vm._l(p.ListProtocolLevelTwo, function (l2) {
                              return _c(
                                "div",
                                {
                                  key: l2.ConfigurationHasProductLevelTwo.ID,
                                  staticClass:
                                    "md-layout md-layout-item md-size-100",
                                  class: {
                                    "dependency ": l2.isDependency,
                                    hide: p.isDependency && !_vm.showAll,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-size-35 fs-italic",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "- " +
                                              l2.ProductLevelTwo.Name.split(
                                                "techDependency "
                                              )[1]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-25",
                                    },
                                    [_vm._v(_vm._s(l2.ProductLevelTwo.LicName))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-10",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          l2.ConfigurationHasProductLevelTwo
                                            .Count
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-20",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            l2.ConfigurationHasProductLevelTwo
                                              .Limited === "9999-12-31"
                                              ? "permanent"
                                              : l2.ConfigurationHasProductLevelTwo.Limited.split(
                                                  "T"
                                                )[0]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "md-layout-item md-size-10",
                                  }),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }