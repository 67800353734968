var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "full-page",
      class: { "nav-open": _vm.$sidebar.showSidebar },
      attrs: { "data-background-color": _vm.userStore.theme },
    },
    [
      _c(
        "md-toolbar",
        {
          staticClass: "md-transparent md-toolbar-absolute",
          attrs: { "md-elevation": "0" },
        },
        [
          _c(
            "div",
            { staticClass: "md-toolbar-row md-offset" },
            [
              _c(
                "div",
                { staticClass: "md-collapse" },
                [
                  _c(
                    "div",
                    { staticClass: "theme-switch" },
                    [
                      _c("div", { staticClass: "theme-color" }, [
                        _vm._v(_vm._s(_vm.$t("darkTheme"))),
                      ]),
                      _c("md-switch", {
                        model: {
                          value: _vm.isWhiteTheme,
                          callback: function ($$v) {
                            _vm.isWhiteTheme = $$v
                          },
                          expression: "isWhiteTheme",
                        },
                      }),
                      _c("div", { staticClass: "theme-color" }, [
                        _vm._v(_vm._s(_vm.$t("lightTheme"))),
                      ]),
                    ],
                    1
                  ),
                  _c("lang-select", { staticClass: "navbar-lang" }),
                ],
                1
              ),
              _c("cookie-notice"),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "wrapper wrapper-full-page",
          on: { click: _vm.toggleSidebarPage },
        },
        [
          _c(
            "div",
            { staticClass: "page-header header-filter" },
            [
              _c(
                "div",
                { staticClass: "container md-offset" },
                [
                  _c(
                    "zoom-center-transition",
                    {
                      attrs: {
                        duration: _vm.pageTransitionDuration,
                        mode: "out-in",
                      },
                    },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
              !_vm.$route.meta.hideFooter ? _c("content-footer") : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }