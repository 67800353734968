var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100",
      },
      [
        _c(
          "md-card",
          { staticClass: "md-card-roles-table" },
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-primary" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("vpn_key")])],
                  1
                ),
                _c("h4", [_vm._v(_vm._s(_vm.$t("overViewRoles")))]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-size-100 md-space-between config-content roles-header",
                  },
                  [
                    _c("md-field", [
                      _c("div", { staticClass: "md-layout-item md-size-15" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("role")))]),
                      ]),
                      _c("div", { staticClass: "md-layout-item md-size-70" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("description")))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-15 text-right" },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("controls")))])]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._l(_vm.mdUserRoles, function (role) {
                  return _c("md-field", { key: role.ID }, [
                    _c("div", { staticClass: "md-layout content-row" }, [
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-15 name-div" },
                        [_c("span", [_vm._v(_vm._s(role.Name))])]
                      ),
                      _c("div", { staticClass: "md-layout-item md-size-70" }, [
                        _c("span", [_vm._v(_vm._s(role.Description))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-15 controls" },
                        [
                          _c(
                            "div",
                            { staticClass: "icon-container" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "animated-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(role)
                                    },
                                  },
                                },
                                [_vm._v("edit")]
                              ),
                              _c("md-tooltip", [
                                _vm._v(_vm._s(_vm.$t("edit"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "icon-container" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "animated-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(role)
                                    },
                                  },
                                },
                                [_vm._v("delete")]
                              ),
                              _c("md-tooltip", [
                                _vm._v(_vm._s(_vm.$t("delete"))),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ])
                }),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-100 text-right pt-15",
                  },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-primary md-round btn-custom",
                        on: { click: _vm.handleCreate },
                      },
                      [
                        _vm._v(_vm._s(_vm.$t("create")) + " "),
                        _c("md-tooltip", [_vm._v(_vm._s(_vm.$t("newRole")))]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }