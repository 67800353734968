var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "md-layout scroll-auto md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100",
    },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _c(
        "div",
        { key: _vm.renderKey },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                {
                  staticClass:
                    "md-card-header-icon md-card-header-primary md-flex",
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("assignment")])],
                    1
                  ),
                  _c("h4", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("customers"))),
                  ]),
                ]
              ),
              _c(
                "md-card-content",
                [
                  _c(
                    "md-table",
                    {
                      staticClass: "paginated-table table-striped table-hover",
                      attrs: {
                        value: _vm.queriedData,
                        "md-sort": _vm.currentSort,
                        "md-sort-order": _vm.currentSortOrder,
                        "md-sort-fn": _vm.customSort,
                      },
                      on: {
                        "update:mdSort": function ($event) {
                          _vm.currentSort = $event
                        },
                        "update:md-sort": function ($event) {
                          _vm.currentSort = $event
                        },
                        "update:mdSortOrder": function ($event) {
                          _vm.currentSortOrder = $event
                        },
                        "update:md-sort-order": function ($event) {
                          _vm.currentSortOrder = $event
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "md-table-row",
                          fn: function (ref) {
                            var item = ref.item
                            var index = ref.index
                            return _c(
                              "md-table-row",
                              {},
                              [
                                _c(
                                  "md-table-cell",
                                  { attrs: { "md-label": "#" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.calculatePosition(index)) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-table-cell",
                                  {
                                    attrs: {
                                      "md-label": _vm.$t("customerNumber"),
                                      "md-sort-by": "CustomerNr",
                                    },
                                  },
                                  [
                                    _c("strong", [_vm._v(_vm._s(item.Name))]),
                                    _c("br"),
                                    _vm._v(_vm._s(item.CustomerNr)),
                                  ]
                                ),
                                _c(
                                  "md-table-cell",
                                  {
                                    attrs: {
                                      "md-label": _vm.$t("customerType"),
                                      "md-sort-by": "CompanyTypeID",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getCompanyType(item.CompanyTypeID)
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-table-cell",
                                  { attrs: { "md-label": _vm.$t("actions") } },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-just-icon md-primary md-simple",
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleEdit(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("md-icon", [_vm._v("edit")]),
                                        _c("md-tooltip", [
                                          _vm._v(_vm._s(_vm.$t("edit"))),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm.allow(
                                      "{52b5e28f-35a2-4d87-96f3-de8bfdd4bb0b}"
                                    )
                                      ? _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-just-icon md-danger md-simple",
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.handleDelete(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("md-icon", [_vm._v("close")]),
                                            _c("md-tooltip", [
                                              _vm._v(_vm._s(_vm.$t("delete"))),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "md-table-toolbar",
                        [
                          _c(
                            "div",
                            { staticClass: "toolbar-left" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("div", [
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "pages", id: "perPage" },
                                      },
                                      [_vm._v(_vm._s(this.$t("perPage")))]
                                    ),
                                  ]),
                                  _c(
                                    "md-select",
                                    {
                                      attrs: { name: "pages" },
                                      model: {
                                        value: _vm.pagination.perPage,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.pagination,
                                            "perPage",
                                            $$v
                                          )
                                        },
                                        expression: "pagination.perPage",
                                      },
                                    },
                                    _vm._l(
                                      _vm.pagination.perPageOptions,
                                      function (item) {
                                        return _c(
                                          "md-option",
                                          {
                                            key: item,
                                            attrs: { label: item, value: item },
                                          },
                                          [_vm._v(" " + _vm._s(item) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "md-field",
                                { staticClass: "ml-10" },
                                [
                                  _c("md-input", {
                                    staticClass: "mb-3",
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      type: "search",
                                      clearable: "",
                                      placeholder: _vm.searchRecords,
                                    },
                                    model: {
                                      value: _vm.searchQuery,
                                      callback: function ($$v) {
                                        _vm.searchQuery = $$v
                                      },
                                      expression: "searchQuery",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "md-field",
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-primary md-md md-round",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleCreate()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(this.$t("createNewCustomer")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-card-actions",
                { attrs: { "md-alignment": "space-between" } },
                [
                  _c("div", {}, [
                    _c("p", { staticClass: "card-category" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("show")) +
                          " " +
                          _vm._s(_vm.from + 1) +
                          " " +
                          _vm._s(_vm.$t("to")) +
                          " " +
                          _vm._s(_vm.to) +
                          " " +
                          _vm._s(_vm.$t("of")) +
                          " " +
                          _vm._s(_vm.total) +
                          " " +
                          _vm._s(_vm.$t("entries")) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("pagination", {
                    staticClass: "pagination-no-border pagination-success",
                    attrs: {
                      "per-page": _vm.pagination.perPage,
                      total: _vm.total,
                    },
                    model: {
                      value: _vm.pagination.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "currentPage", $$v)
                      },
                      expression: "pagination.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }