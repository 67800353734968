var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50 md-medium-size-100" },
      [
        _c("ValidationObserver", {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var handleSubmit = ref.handleSubmit
                var invalid = ref.invalid
                return [
                  _c(
                    "form",
                    {
                      staticClass: "custom-scrollbar",
                      attrs: { id: "company-profile" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return handleSubmit(_vm.submit)
                        },
                      },
                    },
                    [
                      _c("loader", { attrs: { loader: _vm.loader } }),
                      _c(
                        "div",
                        { staticClass: "company-data" },
                        [
                          _c(
                            "md-card",
                            {
                              key: _vm.renderKey,
                              staticClass: "card-content",
                              attrs: { id: "md-card" },
                            },
                            [
                              _c(
                                "md-card-header",
                                {
                                  staticClass: "md-card-header-icon",
                                  class: _vm.getClass(_vm.headerColor),
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "card-icon" },
                                    [_c("md-icon", [_vm._v("factory")])],
                                    1
                                  ),
                                  _c("h4", { staticClass: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("customerProfileTitle")) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c("md-card-content", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout scroll-behavior custom-pt",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$t("customerID"))
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: {
                                                type: "text",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.company.ID,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "ID",
                                                    $$v
                                                  )
                                                },
                                                expression: "company.ID",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(_vm._s(_vm.$t("vatNr"))),
                                            ]),
                                            _c("md-input", {
                                              attrs: { type: "text" },
                                              on: { change: _vm.toggleSave },
                                              model: {
                                                value: _vm.company.VATnr,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "VATnr",
                                                    $$v
                                                  )
                                                },
                                                expression: "company.VATnr",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "customerNo",
                                                rules: "required|numeric",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var passed = ref.passed
                                                      var failed = ref.failed
                                                      return [
                                                        _c(
                                                          "md-field",
                                                          {
                                                            class: [
                                                              {
                                                                "md-error":
                                                                  failed,
                                                              },
                                                              {
                                                                "md-valid":
                                                                  passed,
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "customerNumber"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _c("md-input", {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.toggleSave,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.company
                                                                    .CustomerNr,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.company,
                                                                      "CustomerNr",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "company.CustomerNr",
                                                              },
                                                            }),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            failed,
                                                                          expression:
                                                                            "failed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "error",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "close"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            passed,
                                                                          expression:
                                                                            "passed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "success",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "done"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "companyName",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var passed = ref.passed
                                                      var failed = ref.failed
                                                      return [
                                                        _c(
                                                          "md-field",
                                                          {
                                                            class: [
                                                              {
                                                                "md-error":
                                                                  failed,
                                                              },
                                                              {
                                                                "md-valid":
                                                                  passed,
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "companyName"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _c("md-input", {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.toggleSave,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.company
                                                                    .Name,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.company,
                                                                      "Name",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "company.Name",
                                                              },
                                                            }),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            failed,
                                                                          expression:
                                                                            "failed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "error",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "close"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            passed,
                                                                          expression:
                                                                            "passed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "success",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "done"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "companyType" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("companyType"))
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "md-select",
                                              {
                                                attrs: {
                                                  name: "companyType",
                                                  id: "companyType",
                                                },
                                                on: { input: _vm.toggleSave },
                                                model: {
                                                  value:
                                                    _vm.company.CompanyTypeID,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.company,
                                                      "CompanyTypeID",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "company.CompanyTypeID",
                                                },
                                              },
                                              _vm._l(
                                                _vm.companyTypesData,
                                                function (cType) {
                                                  return _c(
                                                    "md-option",
                                                    {
                                                      key: cType.ID,
                                                      attrs: {
                                                        value: cType.ID,
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(cType.Name))]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "companyStreet",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var passed = ref.passed
                                                      var failed = ref.failed
                                                      return [
                                                        _c(
                                                          "md-field",
                                                          {
                                                            class: [
                                                              {
                                                                "md-error":
                                                                  failed,
                                                              },
                                                              {
                                                                "md-valid":
                                                                  passed,
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "street"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _c("md-input", {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.toggleSave,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.company
                                                                    .Street,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.company,
                                                                      "Street",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "company.Street",
                                                              },
                                                            }),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            failed,
                                                                          expression:
                                                                            "failed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "error",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "close"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            passed,
                                                                          expression:
                                                                            "passed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "success",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "done"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "companyHsNo",
                                                rules: "required|numeric",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var passed = ref.passed
                                                      var failed = ref.failed
                                                      return [
                                                        _c(
                                                          "md-field",
                                                          {
                                                            class: [
                                                              {
                                                                "md-error":
                                                                  failed,
                                                              },
                                                              {
                                                                "md-valid":
                                                                  passed,
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "houseNumber"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _c("md-input", {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.toggleSave,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.company
                                                                    .Housenumber,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.company,
                                                                      "Housenumber",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "company.Housenumber",
                                                              },
                                                            }),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            failed,
                                                                          expression:
                                                                            "failed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "error",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "close"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            passed,
                                                                          expression:
                                                                            "passed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "success",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "done"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "zipcode",
                                                rules: "required|numeric",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var passed = ref.passed
                                                      var failed = ref.failed
                                                      return [
                                                        _c(
                                                          "md-field",
                                                          {
                                                            class: [
                                                              {
                                                                "md-error":
                                                                  failed,
                                                              },
                                                              {
                                                                "md-valid":
                                                                  passed,
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "zipCode"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                            _c("md-input", {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.toggleSave,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.company
                                                                    .ZipCode,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.company,
                                                                      "ZipCode",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "company.ZipCode",
                                                              },
                                                            }),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            failed,
                                                                          expression:
                                                                            "failed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "error",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "close"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            passed,
                                                                          expression:
                                                                            "passed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "success",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "done"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "companyCity",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var passed = ref.passed
                                                      var failed = ref.failed
                                                      return [
                                                        _c(
                                                          "md-field",
                                                          {
                                                            class: [
                                                              {
                                                                "md-error":
                                                                  failed,
                                                              },
                                                              {
                                                                "md-valid":
                                                                  passed,
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("city")
                                                                )
                                                              ),
                                                            ]),
                                                            _c("md-input", {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.toggleSave,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.company
                                                                    .City,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.company,
                                                                      "City",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "company.City",
                                                              },
                                                            }),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            failed,
                                                                          expression:
                                                                            "failed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "error",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "close"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "slide-y-down-transition",
                                                              [
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            passed,
                                                                          expression:
                                                                            "passed",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "success",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "done"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c("select-country", {
                                          attrs: {
                                            value:
                                              _vm.company.Language.toUpperCase(),
                                          },
                                          on: {
                                            input: _vm.toggleSave,
                                            changeCountry: _vm.handleCountry,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "email",
                                            rules: "email",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var passed = ref.passed
                                                  var failed = ref.failed
                                                  return [
                                                    _c(
                                                      "md-field",
                                                      {
                                                        class: [
                                                          {
                                                            "md-error": failed,
                                                          },
                                                          {
                                                            "md-valid": passed,
                                                          },
                                                        ],
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("email")
                                                            )
                                                          ),
                                                        ]),
                                                        _c("md-input", {
                                                          attrs: {
                                                            type: "email",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.toggleSave,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.company.Email,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.company,
                                                                "Email",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "company.Email",
                                                          },
                                                        }),
                                                        _c(
                                                          "slide-y-down-transition",
                                                          [
                                                            _c(
                                                              "md-icon",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      failed,
                                                                    expression:
                                                                      "failed",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "error",
                                                              },
                                                              [_vm._v("close")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "slide-y-down-transition",
                                                          [
                                                            _c(
                                                              "md-icon",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      passed,
                                                                    expression:
                                                                      "passed",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "success",
                                                              },
                                                              [_vm._v("done")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$t("phoneNumber"))
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: { type: "text" },
                                              on: { change: _vm.toggleSave },
                                              model: {
                                                value: _vm.company.Phone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "Phone",
                                                    $$v
                                                  )
                                                },
                                                expression: "company.Phone",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$t("faxNumber"))
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: { type: "text" },
                                              on: { change: _vm.toggleSave },
                                              model: {
                                                value: _vm.company.Fax,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "Fax",
                                                    $$v
                                                  )
                                                },
                                                expression: "company.Fax",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(_vm._s(_vm.$t("website"))),
                                            ]),
                                            _c("md-input", {
                                              attrs: { type: "text" },
                                              on: { change: _vm.toggleSave },
                                              model: {
                                                value: _vm.company.Website,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "Website",
                                                    $$v
                                                  )
                                                },
                                                expression: "company.Website",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-33",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("serviceContract")
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "md-select",
                                              {
                                                attrs: {
                                                  name: "serviceContract",
                                                  id: "serviceContract",
                                                },
                                                on: { input: _vm.toggleSave },
                                                model: {
                                                  value:
                                                    _vm.company.ServiceContract,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.company,
                                                      "ServiceContract",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "company.ServiceContract",
                                                },
                                              },
                                              [
                                                _c(
                                                  "md-option",
                                                  { attrs: { value: "true" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("yes"))
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "md-option",
                                                  { attrs: { value: "false" } },
                                                  [_vm._v(_vm._s(_vm.$t("no")))]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-33",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("serviceContractStart")
                                                )
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: { type: "date" },
                                              on: { change: _vm.toggleSave },
                                              model: {
                                                value:
                                                  _vm.company
                                                    .ServiceContractStart,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "ServiceContractStart",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "company.ServiceContractStart",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-33",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("serviceContractEnd")
                                                )
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: { type: "date" },
                                              on: { change: _vm.toggleSave },
                                              model: {
                                                value:
                                                  _vm.company
                                                    .ServiceContractEnd,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "ServiceContractEnd",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "company.ServiceContractEnd",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$t("createdBy"))
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: {
                                                type: "text",
                                                disabled: "",
                                              },
                                              on: { change: _vm.toggleSave },
                                              model: {
                                                value: _vm.company.CreatedBy,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "CreatedBy",
                                                    $$v
                                                  )
                                                },
                                                expression: "company.CreatedBy",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$t("createdOn"))
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: {
                                                type: "text",
                                                disabled: "",
                                              },
                                              on: { change: _vm.toggleSave },
                                              model: {
                                                value: _vm.company.Created,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "Created",
                                                    $$v
                                                  )
                                                },
                                                expression: "company.Created",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$t("modifiedBy"))
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: {
                                                type: "text",
                                                disabled: "",
                                              },
                                              on: { change: _vm.toggleSave },
                                              model: {
                                                value: _vm.company.ModifiedBy,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "ModifiedBy",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "company.ModifiedBy",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$t("modifiedOn"))
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: {
                                                type: "text",
                                                disabled: "",
                                              },
                                              on: { change: _vm.toggleSave },
                                              model: {
                                                value: _vm.company.Modified,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "Modified",
                                                    $$v
                                                  )
                                                },
                                                expression: "company.Modified",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.allow(
                                      "{60d57c5c-bd17-405c-9e1e-290038937b07}"
                                    )
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-50 text-bottom",
                                            class: { entered: _vm.dragging },
                                            on: {
                                              drop: function ($event) {
                                                $event.preventDefault()
                                                return _vm.onFileChange.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              dragover: function ($event) {
                                                $event.preventDefault()
                                                return _vm.handleDragOver.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              dragleave: _vm.handleDragLeave,
                                            },
                                          },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-round md-primary md-fileinput btn-custom",
                                              },
                                              [
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("loadLicFile")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                _c("input", {
                                                  attrs: {
                                                    accept: ".lic",
                                                    type: "file",
                                                  },
                                                  on: {
                                                    change: _vm.onFileChange,
                                                  },
                                                }),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("div", {
                                          staticClass:
                                            "md-layout-item md-size-50 text-bottom",
                                        }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-size-50 text-right",
                                      },
                                      [
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-raised md-primary mt-4 md-round",
                                            attrs: {
                                              id: "submit",
                                              disabled:
                                                invalid || !_vm.enableSave,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateCompany()
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("save")) + " ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c("div", { staticClass: "spacer" }),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50 md-medium-size-100" },
      [_c("customer-contacts", { attrs: { companyID: this.company.ID } })],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [_c("products-list")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }