var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "a",
      {
        staticClass: "md-primary md-round classic-modal link-settings",
        on: {
          click: function ($event) {
            _vm.settingsModal = true
          },
        },
      },
      [_vm._v(_vm._s(_vm.linkToSettings))]
    ),
    _c(
      "div",
      { staticClass: "mmodal-content" },
      [
        _vm.settingsModal
          ? _c(
              "modal",
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c("h4", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("cookieSettingsTitle"))),
                    ]),
                    _c(
                      "md-button",
                      {
                        staticClass:
                          "md-simple md-just-icon md-round modal-default-button",
                        on: { click: _vm.settingsModalHide },
                      },
                      [_c("md-icon", [_vm._v("clear")])],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { staticClass: "modal-body", slot: "body" }, [
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.$t("cookiesSettingsText")) + " "),
                    _c(
                      "a",
                      {
                        staticClass: "privacy-policy",
                        attrs: {
                          href: _vm.$t("privacyLink"),
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("privacyPolicy")))]
                    ),
                    _vm._v(". "),
                    _c("hr"),
                    _c(
                      "div",
                      { staticClass: "essential-cookies" },
                      [
                        _c("md-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.essis,
                            callback: function ($$v) {
                              _vm.essis = $$v
                            },
                            expression: "essis",
                          },
                        }),
                        _c("div", { staticClass: "essential-cookies-descrp" }, [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("essentialCookiesTitle")) +
                                " "
                            ),
                            _c("span", { staticClass: "es-required" }, [
                              _vm._v(_vm._s(_vm.$t("alwaysRequired"))),
                            ]),
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("essentialCookiesText"))),
                          ]),
                          _c("div", { staticClass: "purposes" }, [
                            _vm._v(_vm._s(_vm.$t("purposeEssential"))),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marketing-cookies" },
                      [
                        _c("md-switch", {
                          model: {
                            value: _vm.stats,
                            callback: function ($$v) {
                              _vm.stats = $$v
                            },
                            expression: "stats",
                          },
                        }),
                        _c("div", { staticClass: "marketing-cookies-descrp" }, [
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("marketingCookies"))),
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("marketingCookiesText"))),
                          ]),
                          _c("div", { staticClass: "purposes" }, [
                            _vm._v(_vm._s(_vm.$t("purposeMarketing"))),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "template",
                  { slot: "footer" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-primary md-round button-width",
                        on: {
                          click: function ($event) {
                            ;[_vm.settingsModalSave(), _vm.classicModalHide()]
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("saveSelection")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "template",
                  { slot: "footer" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-primary md-round button-width",
                        on: { click: _vm.settingsModalDecline },
                      },
                      [_vm._v(_vm._s(_vm.$t("decline")))]
                    ),
                  ],
                  1
                ),
              ],
              2
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }