var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _vm.showCreatePermission
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "template-modal" },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.$t("addPermissionTitle"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "md-layout" }, [
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("name"))),
                                  ]),
                                  _c("md-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.addPermission.Name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addPermission, "Name", $$v)
                                      },
                                      expression: "addPermission.Name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("description"))),
                                  ]),
                                  _c("md-textarea", {
                                    model: {
                                      value: _vm.addPermission.Description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addPermission,
                                          "Description",
                                          $$v
                                        )
                                      },
                                      expression: "addPermission.Description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1992281400
              ),
            },
            [
              _c("template", { slot: "footer" }, [
                _c("div", { staticClass: "md-layout" }, [
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-50" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-simple md-round",
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v(_vm._s(_vm.$t("cancel")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-50 text-right" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-primary md-round",
                          on: { click: _vm.handleCreatePermission },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var invalid = ref.invalid
                  return [
                    _c(
                      "form",
                      [
                        _c("loader", { attrs: { loader: _vm.loader } }),
                        _c(
                          "md-card",
                          {
                            staticClass:
                              "md-layout-item card-content edit-role-card",
                          },
                          [
                            _c(
                              "md-card-header",
                              {
                                staticClass:
                                  "md-card-header-icon header-style md-card-header-primary",
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "card-icon" },
                                    [_c("md-icon", [_vm._v("vpn_key")])],
                                    1
                                  ),
                                  _c("h3", { staticClass: "title" }, [
                                    _vm._v(" " + _vm._s(_vm.cardTitle) + " "),
                                  ]),
                                  _c("h4", [
                                    _vm._v(_vm._s(_vm.$t("createProductText"))),
                                  ]),
                                ]),
                              ]
                            ),
                            _c("md-card-content", [
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item md-size-100" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "roleN",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var passed = ref.passed
                                              var failed = ref.failed
                                              return [
                                                _c(
                                                  "md-field",
                                                  {
                                                    class: [
                                                      { "md-error": failed },
                                                      { "md-valid": passed },
                                                    ],
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("roleName")
                                                        )
                                                      ),
                                                    ]),
                                                    _c("md-input", {
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value: _vm.mdRole.Name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.mdRole,
                                                            "Name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "mdRole.Name",
                                                      },
                                                    }),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: failed,
                                                                expression:
                                                                  "failed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [_vm._v("close")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: passed,
                                                                expression:
                                                                  "passed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "success",
                                                          },
                                                          [_vm._v("done")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-small-size-100",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "roleDesc",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var passed = ref.passed
                                              var failed = ref.failed
                                              return [
                                                _c(
                                                  "md-field",
                                                  {
                                                    staticClass:
                                                      "md-has-value no-bg",
                                                    class: [
                                                      { "md-error": failed },
                                                      { "md-valid": passed },
                                                    ],
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "roleDescription"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("md-textarea", {
                                                      model: {
                                                        value:
                                                          _vm.mdRole
                                                            .Description,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.mdRole,
                                                            "Description",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "mdRole.Description",
                                                      },
                                                    }),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: failed,
                                                                expression:
                                                                  "failed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [_vm._v("close")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "slide-y-down-transition",
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: passed,
                                                                expression:
                                                                  "passed",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "success",
                                                          },
                                                          [_vm._v("done")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                !_vm.edit
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-size-100 text-right pt-15",
                                      },
                                      [
                                        _c(
                                          "md-button",
                                          {
                                            staticClass: "md-primary md-round",
                                            attrs: { disabled: invalid },
                                            on: { click: _vm.handleCreate },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("save")) + " ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-size-100 text-right pt-15",
                                      },
                                      [
                                        _c(
                                          "md-button",
                                          {
                                            staticClass: "md-primary md-round",
                                            attrs: { disabled: invalid },
                                            on: { click: _vm.handleSave },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("save")) + " ")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      this.mdRole.ID
        ? _c(
            "div",
            { key: _vm.renderKey, staticClass: "md-layout-item md-size-50" },
            [
              _c("role-permissions", {
                attrs: { role: this.mdRole },
                on: { showModal: _vm.openModal },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }