var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.role.ID
    ? _c(
        "md-card",
        { key: _vm.renderKey, staticClass: "card-content edit-role-card" },
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-primary" },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("checklist_rtl")])],
                  1
                ),
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("editPermissions"))),
                ]),
                _c("h4", [_vm._v(_vm._s(_vm.role.Name))]),
              ]),
            ]
          ),
          _c("md-card-content", [
            _c(
              "div",
              { staticClass: "md-layout-item" },
              [
                _c("div", { staticClass: "md-layout" }, [
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-33" },
                    [
                      _c(
                        "md-field",
                        [
                          _c("label", [_vm._v(_vm._s(_vm.$t("search")))]),
                          _c("md-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-66 text-right" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-primary md-round",
                          on: { click: _vm.handleCreate },
                        },
                        [
                          _vm._v(_vm._s(_vm.$t("create")) + " "),
                          _c("md-tooltip", [
                            _vm._v(_vm._s(_vm.$t("newPermission"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "md-layout" },
                  [
                    _c("md-field", [
                      _c("div", { staticClass: "md-layout-item md-size-35" }, [
                        _vm._v(" " + _vm._s(_vm.$t("name")) + " "),
                      ]),
                      _c("div", { staticClass: "md-layout-item md-size-55" }, [
                        _vm._v(" " + _vm._s(_vm.$t("description")) + " "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-10 text-right" },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("permission")))])]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._l(_vm.filteredPermissions, function (permission, index) {
                  return _c(
                    "div",
                    {
                      key: permission.Name,
                      staticClass: "md-layout",
                      class: { "bg-table": index % 2 !== 0 },
                    },
                    [
                      _c("div", { staticClass: "md-layout-item md-size-35" }, [
                        _c("span", [_vm._v(_vm._s(permission.Name))]),
                        _c("br"),
                        _c("span", { staticClass: "permission-id" }, [
                          _vm._v(_vm._s(permission.ID)),
                        ]),
                      ]),
                      _c("div", { staticClass: "md-layout-item md-size-55" }, [
                        _c("span", [_vm._v(_vm._s(permission.Description))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-10 pr-5" },
                        [
                          _c("md-checkbox", {
                            on: {
                              change: function ($event) {
                                return _vm.updateRolePermission(permission)
                              },
                            },
                            model: {
                              value: permission.Granted,
                              callback: function ($$v) {
                                _vm.$set(permission, "Granted", $$v)
                              },
                              expression: "permission.Granted",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }