var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "md-layout md-size-33 md-medium-size-100 md-small-size-100 md-xsmall-size-100",
    },
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-default" },
            [
              _c("h2", { staticClass: "title text-center" }, [
                _vm._v(" " + _vm._s(_vm.$t("setPasswordTitle")) + " "),
              ]),
            ]
          ),
          _c(
            "md-card-content",
            { staticClass: "sucess-text" },
            [
              _vm._v(" " + _vm._s(_vm.$t("setPasswordRule")) + " "),
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var invalid = ref.invalid
                      return [
                        _c("ValidationProvider", {
                          attrs: {
                            name: "password",
                            rules: {
                              required: true,
                              regex: _vm.regExValid,
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var passed = ref.passed
                                  var failed = ref.failed
                                  return [
                                    _c(
                                      "md-field",
                                      {
                                        class: [
                                          { "md-error": failed },
                                          { "md-valid": passed },
                                          "new-password",
                                        ],
                                        attrs: { required: "" },
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(_vm.$t("password"))),
                                        ]),
                                        _c("md-input", {
                                          attrs: { type: "password" },
                                          model: {
                                            value: _vm.password,
                                            callback: function ($$v) {
                                              _vm.password = $$v
                                            },
                                            expression: "password",
                                          },
                                        }),
                                        _c(
                                          "slide-y-down-transition",
                                          [
                                            _c(
                                              "md-icon",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: failed,
                                                    expression: "failed",
                                                  },
                                                ],
                                                staticClass: "error password",
                                              },
                                              [_vm._v("close")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "slide-y-down-transition",
                                          [
                                            _c(
                                              "md-icon",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: passed,
                                                    expression: "passed",
                                                  },
                                                ],
                                                staticClass: "success password",
                                              },
                                              [_vm._v("done")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c(
                          "div",
                          { staticClass: "button-container" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-primary md-round mt-4",
                                attrs: { slot: "footer", disabled: invalid },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.submitNewPassword()
                                  },
                                },
                                slot: "footer",
                              },
                              [_vm._v(_vm._s(_vm.$t("submit")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }