var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    {
      class: {
        "md-card-nav-mdesign": !_vm.view,
        "md-card-nav-mdesign-view": _vm.view,
      },
    },
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-primary" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("assignment")])],
            1
          ),
          _vm._t("header"),
        ],
        2
      ),
      _c("md-card-content", [_vm._t("contentNav")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }