var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "sidebar sidebar-first sidebar-mini": true,
        "show-mob-menu": _vm.$sidebar.showSidebar,
      },
      attrs: {
        "data-color": _vm.activeColor,
        "data-background-color": _vm.backgroundColor,
      },
    },
    [
      !_vm.$sidebar.showSidebar
        ? _c("div", { staticClass: "logo" }, [
            _c(
              "a",
              {
                staticClass: "simple-text",
                attrs: { href: "http://my.mdesign.de/", target: "_blank" },
              },
              [
                _c("div", { staticClass: "logo-img company-logo" }, [
                  !_vm.$sidebar.showProductMenu
                    ? _c("img", {
                        attrs: { src: _vm.logoMini, alt: "company_small_logo" },
                      })
                    : _vm._e(),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm.$sidebar.showSidebar
        ? _c("div", { staticClass: "logo" }, [
            _c(
              "a",
              {
                staticClass: "simple-text",
                attrs: { href: "http://my.mdesign.de/", target: "_blank" },
              },
              [
                _c("div", { staticClass: "company-logo" }, [
                  _c("img", {
                    staticClass: "logo-big",
                    attrs: { src: _vm.logoBig, alt: "company_big_logo" },
                  }),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      this.firstname
        ? _c("div", { staticClass: "logo" }, [
            _c(
              "a",
              {
                staticClass: "simple-text logo-mini cursor-default",
                on: { click: _vm.goToUserProfile },
              },
              [
                _c("div", { staticClass: "logo-img profile-image tooltip" }, [
                  _c("img", { attrs: { src: _vm.profileImage } }),
                  _c("span", { staticClass: "tooltiptext" }, [
                    _vm._v(_vm._s(_vm.$t("profileTitle"))),
                  ]),
                ]),
              ]
            ),
            this.firstname && _vm.$sidebar.showSidebar
              ? _c("div", { staticClass: "logo" }, [
                  _c(
                    "a",
                    {
                      staticClass: "simple-text logo-normal",
                      attrs: { renderKey: _vm.updateName },
                      on: { click: _vm.goToUserProfile },
                    },
                    [
                      _c("div", { staticClass: "name-surname" }, [
                        _vm._v(
                          _vm._s(_vm.firstname) + " " + _vm._s(_vm.lastname)
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      !this.firstname
        ? _c(
            "div",
            { staticClass: "logo", on: { click: _vm.goToUserProfile } },
            [
              _c("a", { staticClass: "simple-text logo-mini cursor-default" }, [
                _c("div", { staticClass: "logo-img profile-image tooltip" }, [
                  _c("img", { attrs: { src: _vm.profileImage } }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { ref: "sidebarScrollArea", staticClass: "sidebar-wrapper" },
        [
          _c(
            "md-list",
            { staticClass: "nav" },
            [
              _vm._t("links", function () {
                return _vm._l(_vm.sidebarLinks, function (link, index) {
                  return _c(
                    "sidebar-item",
                    { key: link.name + index, attrs: { link: link } },
                    _vm._l(link.children, function (subLink, index) {
                      return _c("sidebar-item", {
                        key: subLink.name + index,
                        attrs: { link: subLink },
                      })
                    }),
                    1
                  )
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }