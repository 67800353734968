var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-product-level-content" },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              return [
                _c(
                  "form",
                  [
                    _c("loader", { attrs: { loader: _vm.loader } }),
                    _vm.productLevel === 1
                      ? _c(
                          "form",
                          { attrs: { enctype: "multipart/form-data" } },
                          [
                            _c(
                              "div",
                              { staticClass: "md-layout-item upload-document" },
                              [
                                _c("div", { staticClass: "file-input" }, [
                                  !_vm.imageRegular
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "image-container" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: _vm.regularImg,
                                                title: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        { staticClass: "image-container" },
                                        [
                                          _c("img", {
                                            attrs: { src: _vm.imageRegular },
                                          }),
                                        ]
                                      ),
                                  _c(
                                    "div",
                                    { staticClass: "button-container" },
                                    [
                                      _vm.imageRegular
                                        ? _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-primary md-round md-fileinput ima-submit",
                                              on: { click: _vm.uploadImage },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("upload")) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.imageRegular
                                        ? _c(
                                            "md-button",
                                            {
                                              staticClass: "md-round md-simple",
                                              on: { click: _vm.removeImage },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("remove")))]
                                          )
                                        : _vm._e(),
                                      !_vm.imageRegular
                                        ? _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-round md-primary md-fileinput ima-change",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("selectImg"))
                                              ),
                                              _c("input", {
                                                attrs: { type: "file" },
                                                on: {
                                                  change: _vm.onFileChange,
                                                },
                                              }),
                                            ]
                                          )
                                        : _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-round md-simple md-fileinput ima-change",
                                            },
                                            [
                                              _vm._v(_vm._s(_vm.$t("change"))),
                                              _c("input", {
                                                attrs: { type: "file" },
                                                on: {
                                                  change: _vm.onFileChange,
                                                },
                                              }),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "md-layout" }, [
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-100" },
                        [
                          _c(
                            "md-field",
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("productName"))),
                              ]),
                              _c("md-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.product.Name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.product, "Name", $$v)
                                  },
                                  expression: "product.Name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-100" },
                        [
                          _c(
                            "md-field",
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("productLicName"))),
                              ]),
                              _c("md-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.product.LicName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.product, "LicName", $$v)
                                  },
                                  expression: "product.LicName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.productLevel === 1
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("productLicNameParent"))
                                    ),
                                  ]),
                                  _c("md-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.product.LicNameParent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.product,
                                          "LicNameParent",
                                          $$v
                                        )
                                      },
                                      expression: "product.LicNameParent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editProduct === null
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-50" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-round md-primary",
                                  on: { click: _vm.createProductLevel },
                                },
                                [_vm._v(_vm._s(_vm.$t("create")))]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "md-layout-item md-size-50" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-round md-primary",
                                  on: { click: _vm.updateProductLevel },
                                },
                                [_vm._v(_vm._s(_vm.$t("save")))]
                              ),
                            ],
                            1
                          ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-50" },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-round md-simple",
                              on: { click: _vm.closeModal },
                            },
                            [_vm._v(_vm._s(_vm.$t("cancel")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }