var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    {
      staticClass: "md-card-product",
      attrs: { "data-count": _vm.hoverCount },
      nativeOn: {
        mouseleave: function ($event) {
          return _vm.onMouseLeave.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "md-card-header",
        {
          staticClass: "md-card-header-image animated",
          class: [
            { hovered: _vm.imgHovered },
            { hinge: _vm.headerDown },
            { fadeInDown: _vm.fixedHeader },
          ],
          attrs: { "data-header-animation": _vm.headerAnimation },
          nativeOn: {
            mouseenter: function ($event) {
              return _vm.onMouseOver.apply(null, arguments)
            },
          },
        },
        [_vm._t("imageHeader")],
        2
      ),
      _c(
        "md-card-content",
        [
          _vm.headerAnimation === "true"
            ? _c(
                "div",
                {
                  staticClass:
                    "md-card-action-buttons text-center buttons-wrapper",
                  attrs: { id: "buttons-wrapper" },
                },
                [
                  _vm.headerDown
                    ? _c(
                        "md-button",
                        {
                          staticClass: "md-danger md-simple fix-broken-card",
                          on: { click: _vm.fixHeader },
                        },
                        [
                          _vm._t("fixed-button"),
                          _vm._v(
                            " " + _vm._s(_vm.$t("returnProductImg")) + " "
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "md-button",
                    { staticClass: "md-simple md-just-icon first-button" },
                    [_vm._t("first-button")],
                    2
                  ),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-success md-simple md-just-icon second-button",
                    },
                    [_vm._t("second-button")],
                    2
                  ),
                  _c(
                    "md-button",
                    {
                      staticClass: "third-button",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleDelete()
                        },
                      },
                    },
                    [_vm._t("third-button")],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._t("title"),
          _vm._t("description"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }