var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _c("two-fa-card", { attrs: { "header-color": "primary" } }, [
        _c(
          "div",
          {
            staticClass: "md-card-header-icon md-card-header-default",
            attrs: { slot: "title", "header-color": "primary" },
            slot: "title",
          },
          [
            _c("img", {
              staticClass: "img-title",
              attrs: { src: _vm.logo, alt: "MyMdesignLogo" },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "sucess-text",
            attrs: { slot: "content" },
            slot: "content",
          },
          [
            _c("div", { staticClass: "md-layout md-size-100" }, [
              _c("h3", { staticClass: "title text-center" }, [
                _vm._v(" " + _vm._s(_vm.$t("tfaTitle")) + " "),
              ]),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-100" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("authCode")))]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.tfacode,
                          callback: function ($$v) {
                            _vm.tfacode = $$v
                          },
                          expression: "tfacode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "remaining-time" }, [
              _c("h4", [_vm._v(_vm._s(_vm.$t("remainingTime")))]),
              _c("h4", [
                _vm._v(
                  _vm._s(
                    _vm.remainingTime !== -1
                      ? _vm.remainingTime
                      : _vm.$t("linkExpired")
                  )
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "md-layout",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "div",
              { staticClass: "md-layout-item md-size-100 just-center" },
              [
                _c("div", { staticClass: "resend-info" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("resendMail"))),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("clickHere")) + " "),
                    _c("a", { on: { click: _vm.sendAgain } }, [
                      _vm._v(_vm._s(_vm.$t("resendMailLink"))),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "md-layout-item md-size-100 just-center" },
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-primary md-round",
                    attrs: { disabled: _vm.remainingTime === -1 },
                    on: { click: _vm.comfirmTfa },
                  },
                  [_vm._v(_vm._s(_vm.$t("confirm")))]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }