var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "licence-content" } },
    [
      _c(
        "div",
        { staticClass: "loader-wrapper" },
        [_c("loader", { attrs: { loader: _vm.loader } })],
        1
      ),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  { staticClass: "edit-mdesign-licence" },
                  [
                    _c(
                      "md-card",
                      { staticClass: "card-content" },
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass: "md-card-header-icon",
                            class: _vm.getClass(_vm.headerColor),
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("list")])],
                              1
                            ),
                            _c("div", [
                              _c("h4", [_vm._v(_vm._s(_vm.titleText))]),
                              _c("h5", [_vm._v(_vm._s(_vm.subtitleText))]),
                            ]),
                          ]
                        ),
                        _c("md-card-content", [
                          _c(
                            "div",
                            {
                              staticClass: "md-layout",
                              class: { entered: _vm.dragging },
                              on: {
                                drop: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onFileChange.apply(null, arguments)
                                },
                                dragover: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleDragOver.apply(
                                    null,
                                    arguments
                                  )
                                },
                                dragleave: _vm.handleDragLeave,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-100 content-right",
                                },
                                [
                                  !_vm.readonly
                                    ? _c(
                                        "md-button",
                                        {
                                          staticClass:
                                            "md-round md-primary md-fileinput btn-custom",
                                        },
                                        [
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("loadHostIDFile"))
                                              ),
                                            ]),
                                          ],
                                          _c("input", {
                                            attrs: {
                                              accept: ".id",
                                              type: "file",
                                            },
                                            on: { change: _vm.onFileChange },
                                          }),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-25",
                                },
                                [
                                  _c(
                                    "md-field",
                                    {
                                      staticClass: "has-count",
                                      class: {
                                        "too-long":
                                          _vm.licenceCreatorModel
                                            .CompanyNameShort.length > 43,
                                      },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("company"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          id: "company",
                                          maxlength: "43",
                                        },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel
                                              .CompanyNameShort,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "CompanyNameShort",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.CompanyNameShort",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-25",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("comment"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text", id: "comment" },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel.Comment,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "Comment",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.Comment",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-25",
                                },
                                [
                                  _c("ValidationProvider", {
                                    attrs: { name: "email", rules: "email" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var passed = ref.passed
                                            var failed = ref.failed
                                            return [
                                              _c(
                                                "md-field",
                                                {
                                                  class: [
                                                    { "md-error": failed },
                                                    { "md-valid": passed },
                                                  ],
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("receiver"))
                                                    ),
                                                  ]),
                                                  _c("md-input", {
                                                    attrs: {
                                                      disabled: _vm.readonly,
                                                      type: "email",
                                                      id: "email",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.licenceCreatorModel
                                                          .Email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.licenceCreatorModel,
                                                          "Email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "licenceCreatorModel.Email",
                                                    },
                                                  }),
                                                  _c(
                                                    "slide-y-down-transition",
                                                    [
                                                      _c(
                                                        "md-icon",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: failed,
                                                              expression:
                                                                "failed",
                                                            },
                                                          ],
                                                          staticClass: "error",
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "slide-y-down-transition",
                                                    [
                                                      _c(
                                                        "md-icon",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: passed,
                                                              expression:
                                                                "passed",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "success",
                                                        },
                                                        [_vm._v("done")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-25",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "version" } },
                                        [_vm._v(_vm._s(_vm.$t("version")))]
                                      ),
                                      _c(
                                        "md-select",
                                        {
                                          attrs: {
                                            disabled: "",
                                            name: "version",
                                            id: "version",
                                          },
                                          model: {
                                            value:
                                              _vm.licenceCreatorModel.Version,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.licenceCreatorModel,
                                                "Version",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "licenceCreatorModel.Version",
                                          },
                                        },
                                        [
                                          _c(
                                            "md-option",
                                            { attrs: { value: 17.0 } },
                                            [_vm._v(_vm._s("17.0"))]
                                          ),
                                          _c(
                                            "md-option",
                                            { attrs: { value: 18.0 } },
                                            [_vm._v(_vm._s("18.0"))]
                                          ),
                                          _c(
                                            "md-option",
                                            { attrs: { value: 19.0 } },
                                            [_vm._v(_vm._s("19.0"))]
                                          ),
                                          _c(
                                            "md-option",
                                            { attrs: { value: 20.0 } },
                                            [_vm._v(_vm._s("20.0"))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-33 md-size-25",
                                },
                                [
                                  _c(
                                    "md-switch",
                                    {
                                      attrs: { disabled: _vm.readonly },
                                      on: {
                                        change: function ($event) {
                                          return _vm.toggleSwitch("unlimited")
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.licenceCreatorModel.Unlimited,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.licenceCreatorModel,
                                            "Unlimited",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licenceCreatorModel.Unlimited",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("unlimited")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-33 md-size-25",
                                },
                                [
                                  _c(
                                    "md-switch",
                                    {
                                      attrs: { disabled: _vm.readonly },
                                      model: {
                                        value:
                                          _vm.licenceCreatorModel
                                            .TerminalServer,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.licenceCreatorModel,
                                            "TerminalServer",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licenceCreatorModel.TerminalServer",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("terminalServer")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-33 md-size-25",
                                },
                                [
                                  _c(
                                    "md-switch",
                                    {
                                      attrs: { disabled: _vm.readonly },
                                      model: {
                                        value:
                                          _vm.licenceCreatorModel.VirtualServer,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.licenceCreatorModel,
                                            "VirtualServer",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licenceCreatorModel.VirtualServer",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("virtualServer")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass:
                                  "md-layout-item md-small-size-0 md-size-25",
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-25",
                                },
                                [
                                  _c(
                                    "md-field",
                                    { staticClass: "input-field" },
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("startDate"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "date",
                                          disabled: _vm.readonly,
                                        },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel.StartDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "StartDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.StartDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-25",
                                },
                                [
                                  !_vm.licenceCreatorModel.Unlimited
                                    ? _c(
                                        "md-field",
                                        { staticClass: "input-field" },
                                        [
                                          _c("label", [
                                            _vm._v(_vm._s(_vm.$t("limited"))),
                                          ]),
                                          _c("md-input", {
                                            attrs: {
                                              type: "date",
                                              disabled: _vm.readonly,
                                            },
                                            model: {
                                              value:
                                                _vm.licenceCreatorModel.EndDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.licenceCreatorModel,
                                                  "EndDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "licenceCreatorModel.EndDate",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "md-field",
                                        [
                                          _c("label", [
                                            _vm._v(_vm._s(_vm.$t("limited"))),
                                          ]),
                                          _c("md-input", {
                                            staticClass: "md-input",
                                            attrs: {
                                              type: "text",
                                              disabled: "",
                                              value: _vm.$t("unlimited"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-25",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(_vm.$t("hostIDCreateDate"))
                                        ),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text", disabled: "" },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel
                                              .HostIDCreateDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "HostIDCreateDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.HostIDCreateDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-25",
                                },
                                [
                                  _c(
                                    "md-field",
                                    {
                                      staticClass: "has-count",
                                      class: {
                                        "too-long":
                                          _vm.licenceCreatorModel.CustomerNr
                                            .length > 10,
                                      },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(_vm.$t("customerNumber"))
                                        ),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          maxlength: "10",
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel.CustomerNr,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "CustomerNr",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.CustomerNr",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass:
                                  "md-layout-item md-size-100 spacer",
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-50 md-size-20",
                                },
                                [
                                  _c(
                                    "md-switch",
                                    {
                                      attrs: { disabled: _vm.readonly },
                                      on: {
                                        change: function ($event) {
                                          return _vm.toggleSwitch("macAddress")
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.licenceCreatorModel.IsMacAddress,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.licenceCreatorModel,
                                            "IsMacAddress",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licenceCreatorModel.IsMacAddress",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("macAddress")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-50 md-size-20",
                                },
                                [
                                  _c(
                                    "md-switch",
                                    {
                                      attrs: { disabled: _vm.readonly },
                                      on: {
                                        change: function ($event) {
                                          return _vm.toggleSwitch(
                                            "computerName"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.licenceCreatorModel.IsPcName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.licenceCreatorModel,
                                            "IsPcName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licenceCreatorModel.IsPcName",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("computerName")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-50 md-size-20",
                                },
                                [
                                  _c(
                                    "md-switch",
                                    {
                                      attrs: { disabled: _vm.readonly },
                                      on: {
                                        change: function ($event) {
                                          return _vm.toggleSwitch(
                                            "pcNameAndMac"
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.licenceCreatorModel
                                            .IsMacAddressAndPcName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.licenceCreatorModel,
                                            "IsMacAddressAndPcName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licenceCreatorModel.IsMacAddressAndPcName",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("computerName")) +
                                          " & " +
                                          _vm._s(_vm.$t("macAddress")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-50 md-size-20",
                                },
                                [
                                  _c(
                                    "md-switch",
                                    {
                                      attrs: { disabled: _vm.readonly },
                                      on: {
                                        change: function ($event) {
                                          return _vm.toggleSwitch("triad")
                                        },
                                      },
                                      model: {
                                        value: _vm.licenceCreatorModel.Triade,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.licenceCreatorModel,
                                            "Triade",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licenceCreatorModel.Triade",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("triad")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-50 md-size-20",
                                },
                                [
                                  _c(
                                    "md-switch",
                                    {
                                      attrs: { disabled: _vm.readonly },
                                      on: {
                                        change: function ($event) {
                                          return _vm.toggleSwitch("unbound")
                                        },
                                      },
                                      model: {
                                        value: _vm.licenceCreatorModel.Unbound,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.licenceCreatorModel,
                                            "Unbound",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licenceCreatorModel.Unbound",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("unbound")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.isMediumScreen || _vm.isSmallScreen
                                ? _c("div", {
                                    staticClass:
                                      "md-layout-item md-small-size-50 md-size-0",
                                  })
                                : _vm._e(),
                              _c("div", {
                                staticClass:
                                  "md-layout-item md-size-100 spacer",
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-33",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("port"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          id: "port",
                                          disabled: _vm.readonly,
                                        },
                                        model: {
                                          value: _vm.licenceCreatorModel.Port,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "Port",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.Port",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-66",
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-33",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("macAddress"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            _vm.licenceCreatorModel.Unbound ||
                                            _vm.licenceCreatorModel.IsPcName ||
                                            _vm.readonly,
                                        },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel.MacAddress1,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "MacAddress1",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.MacAddress1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-33",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("macAddress"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            _vm.licenceCreatorModel.Unbound ||
                                            !_vm.licenceCreatorModel.Triade ||
                                            _vm.readonly,
                                        },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel.MacAddress2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "MacAddress2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.MacAddress2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-33",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("macAddress"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            _vm.licenceCreatorModel.Unbound ||
                                            !_vm.licenceCreatorModel.Triade ||
                                            _vm.readonly,
                                        },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel.MacAddress3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "MacAddress3",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.MacAddress3",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-33",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("computerName"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            _vm.licenceCreatorModel.Unbound ||
                                            _vm.licenceCreatorModel
                                              .IsMacAddress ||
                                            _vm.readonly,
                                        },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel.PcName1,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "PcName1",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.PcName1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-33",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("computerName"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            _vm.licenceCreatorModel.Unbound ||
                                            !_vm.licenceCreatorModel.Triade ||
                                            _vm.readonly,
                                        },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel.PcName2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "PcName2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.PcName2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-33",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("computerName"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            _vm.licenceCreatorModel.Unbound ||
                                            !_vm.licenceCreatorModel.Triade ||
                                            _vm.readonly,
                                        },
                                        model: {
                                          value:
                                            _vm.licenceCreatorModel.PcName3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.licenceCreatorModel,
                                              "PcName3",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenceCreatorModel.PcName3",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass:
                                  "md-layout-item md-size-100 spacer",
                              }),
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-small-size-100 md-size-33",
                                  },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-simple md-round btn-custom",
                                        on: { click: _vm.backStep },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("back")))]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-33",
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-small-size-100 md-size-33 text-right",
                                  },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-primary md-round btn-custom",
                                        attrs: { disabled: invalid },
                                        on: { click: _vm.handleCreate },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("save")))]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }