var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "addProd-content" } },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _c(
        "md-card",
        { staticClass: "md-card-add-mdesign" },
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-primary" },
            [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("assignment")])],
                1
              ),
              _vm._t("header", function () {
                return [
                  _c("h4", [_vm._v(_vm._s(_vm.$t("addProducts")))]),
                  _vm.allow("{14e7f1ca-fb12-4460-9f87-a3c21ad16d05}")
                    ? _c(
                        "md-switch",
                        {
                          model: {
                            value: _vm.showDependencies,
                            callback: function ($$v) {
                              _vm.showDependencies = $$v
                            },
                            expression: "showDependencies",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("showAll")))]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "md-card-content",
            [
              _c(
                "md-table",
                {
                  key: _vm.renderKey,
                  staticClass: "md-table-mdesign-configuration",
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function (ref) {
                        var item = ref.item
                        return _c(
                          "md-table-row",
                          {
                            class: {
                              "op-04": item.Count <= 0,
                              "hidden-dep":
                                item.isDependency && !_vm.showDependencies,
                            },
                          },
                          [
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": _vm.$t("name") } },
                              [_vm._v(" " + _vm._s(item.Name) + " ")]
                            ),
                            _c("md-table-cell", { staticClass: "mw-20" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "animated-icon",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.reduceCount(item)
                                    },
                                  },
                                },
                                [_vm._v("remove")]
                              ),
                            ]),
                            _c(
                              "md-table-cell",
                              {
                                staticClass: "mw-80",
                                attrs: { "md-label": _vm.$t("count") },
                              },
                              [
                                _c(
                                  "md-field",
                                  { staticClass: "input-field" },
                                  [
                                    _c("label"),
                                    _c("md-input", {
                                      attrs: {
                                        inputmode: "numeric",
                                        min: "0",
                                        max: "100",
                                        type: "number",
                                      },
                                      model: {
                                        value: item.Count,
                                        callback: function ($$v) {
                                          _vm.$set(item, "Count", $$v)
                                        },
                                        expression: "item.Count",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "md-table-cell",
                              { staticClass: "mw-20 pr-20" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "animated-icon",
                                    on: {
                                      dblclick: function ($event) {
                                        $event.preventDefault()
                                        return _vm.preventDefault.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addCount(item)
                                      },
                                    },
                                  },
                                  [_vm._v("add")]
                                ),
                              ]
                            ),
                            _c(
                              "md-table-cell",
                              {
                                staticClass: "fix-width-25",
                                attrs: { "md-label": _vm.$t("limited") },
                              },
                              [
                                _c(
                                  "md-field",
                                  { staticClass: "input-field just-cont-sb" },
                                  [
                                    item.Limited !== "9999-12-31"
                                      ? _c("span", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.Limited,
                                                expression: "item.Limited",
                                              },
                                            ],
                                            attrs: { type: "date" },
                                            domProps: { value: item.Limited },
                                            on: {
                                              change: function ($event) {
                                                return _vm.onLimitedChange(item)
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  item,
                                                  "Limited",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ])
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "md-input",
                                            attrs: { type: "text" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("unlimited")))]
                                        ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "icon-container",
                                        class: {
                                          selected:
                                            item.Limited === "9999-12-31",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "animated-icon md-pr-30",
                                            on: {
                                              click: function ($event) {
                                                return _vm.setDate(
                                                  "unlimited",
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" all_inclusive ")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.productsToAdd,
                    callback: function ($$v) {
                      _vm.productsToAdd = $$v
                    },
                    expression: "productsToAdd",
                  },
                },
                [
                  _c("md-table-toolbar", { staticClass: "table-toolbar" }, [
                    _c(
                      "div",
                      {
                        staticClass: "md-layout md-gutter md-alignment-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "md-layout-item" },
                          [
                            _c(
                              "md-field",
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-simple md-round",
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.reduceCountAll.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_c("md-icon", [_vm._v("remove")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "md-layout-item" },
                          [
                            _c(
                              "md-field",
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-simple md-round",
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.deselectAll()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("deselectAll")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "md-layout-item" },
                          [
                            _c(
                              "md-field",
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-simple md-round",
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.selectAll()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("selectAll")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "md-layout-item" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-simple md-round",
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.addCountAll.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_c("md-icon", [_vm._v("add")])],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "md-layout-item" },
                          [
                            _c(
                              "md-field",
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-simple md-round",
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.unlimitedAll()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("unlimitedAll")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-small-100 md-size-33",
                  },
                  [
                    _c(
                      "md-field",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-simple md-round",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.backStep()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("back")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", {
                  staticClass:
                    "md-layout-item md-small-size-100 md-size-33 space-between",
                }),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-small-100 md-size-33",
                  },
                  [
                    _c(
                      "md-field",
                      { staticClass: "content-right" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-primary",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.confirmSelection()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("confirm")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }