var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    {
      staticClass: "md-card-chart",
      attrs: { "data-count": _vm.hoverCount },
      nativeOn: {
        mouseleave: function ($event) {
          return _vm.onMouseLeave.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "md-card-header",
        {
          class: [
            { hovered: _vm.imgHovered },
            { hinge: _vm.headerDown },
            { fadeInDown: _vm.fixedHeader },
            { animated: true },
            ((_obj = {}),
            (_obj[_vm.getClass(_vm.backgroundColor)] = true),
            _obj),
            { "md-card-header-text": _vm.HeaderText },
            { "md-card-header-icon": _vm.HeaderIcon },
          ],
          attrs: { "data-header-animation": _vm.headerAnimation },
          nativeOn: {
            mouseenter: function ($event) {
              return _vm.onMouseOver.apply(null, arguments)
            },
          },
        },
        [
          _vm.chartInsideHeader
            ? _c("div", { staticClass: "ct-chart", attrs: { id: _vm.chartId } })
            : _vm._e(),
          _vm._t("chartInsideHeader"),
        ],
        2
      ),
      _c(
        "md-card-content",
        [
          _vm.chartInsideContent
            ? _c("div", { staticClass: "ct-chart", attrs: { id: _vm.chartId } })
            : _vm._e(),
          _vm.headerAnimation === "true"
            ? _c(
                "div",
                {
                  staticClass:
                    "md-card-action-buttons text-center flex-dir-row",
                },
                [
                  _vm.headerDown
                    ? _c(
                        "md-button",
                        {
                          staticClass: "md-danger md-simple fix-broken-card",
                          on: { click: _vm.fixHeader },
                        },
                        [
                          _vm._t("fixed-button"),
                          _vm._v(
                            " " + _vm._s(_vm.$t("returnProductImg")) + " "
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "button-div" },
                    [_vm._t("first-button")],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "button-div" },
                    [_vm._t("second-button")],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "button-div" },
                    [_vm._t("third-button")],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._t("content"),
        ],
        2
      ),
      !_vm.noFooter
        ? _c(
            "md-card-actions",
            { attrs: { "md-alignment": "left" } },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }