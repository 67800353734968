var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "two-tiles" },
      [
        _c("ValidationObserver", {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var handleSubmit = ref.handleSubmit
                var invalid = ref.invalid
                return [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return handleSubmit(_vm.submit)
                        },
                      },
                    },
                    [
                      _c("loader", { attrs: { loader: _vm.loader } }),
                      _c(
                        "md-card",
                        {
                          class: [
                            "md-layout-item",
                            "md-small-size-100",
                            { "md-size-100": _vm.verificationObject },
                            {
                              "md-size-66": !_vm.verificationObject,
                              center: !_vm.verificationObject,
                            },
                          ],
                        },
                        [
                          _c(
                            "md-card-header",
                            {
                              staticClass: "md-card-header-icon",
                              class: _vm.getClass(_vm.headerColor),
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "card-icon" },
                                [_c("md-icon", [_vm._v("perm_identity")])],
                                1
                              ),
                              _c("h4", { staticClass: "title" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("contactData")) + " "
                                ),
                              ]),
                              _c("h3", [
                                _vm._v(_vm._s(_vm.$t("editContactText"))),
                              ]),
                            ]
                          ),
                          _c("md-card-content", [
                            _c("div", { staticClass: "md-layout" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("contactId"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text", disabled: "" },
                                        model: {
                                          value: _vm.contact.ID,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.contact, "ID", $$v)
                                          },
                                          expression: "contact.ID",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(_vm.$t("customerNumber"))
                                        ),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text", disabled: "" },
                                        model: {
                                          value: _vm.contact.CustomerNr,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "CustomerNr",
                                              $$v
                                            )
                                          },
                                          expression: "contact.CustomerNr",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50 salutation",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "salutation" } },
                                        [_vm._v(_vm._s(_vm.$t("salutation")))]
                                      ),
                                      _c(
                                        "md-select",
                                        {
                                          attrs: {
                                            name: "salutation",
                                            id: "salutation",
                                          },
                                          model: {
                                            value: _vm.contact.Salutation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contact,
                                                "Salutation",
                                                $$v
                                              )
                                            },
                                            expression: "contact.Salutation",
                                          },
                                        },
                                        [
                                          _c(
                                            "md-option",
                                            { attrs: { value: "Herr" } },
                                            [_vm._v(_vm._s(_vm.$t("mr")))]
                                          ),
                                          _c(
                                            "md-option",
                                            { attrs: { value: "Frau" } },
                                            [_vm._v(_vm._s(_vm.$t("ms")))]
                                          ),
                                          _c(
                                            "md-option",
                                            {
                                              attrs: { value: "NoSalutation" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("noSalutaition"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("title"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.contact.Title,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.contact, "Title", $$v)
                                          },
                                          expression: "contact.Title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "firstname",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var passed = ref.passed
                                                var failed = ref.failed
                                                return [
                                                  _c(
                                                    "md-field",
                                                    {
                                                      class: [
                                                        { "md-error": failed },
                                                        { "md-valid": passed },
                                                      ],
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("firstName")
                                                          )
                                                        ),
                                                      ]),
                                                      _c("md-input", {
                                                        attrs: { type: "text" },
                                                        model: {
                                                          value:
                                                            _vm.contact
                                                              .FirstName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact,
                                                              "FirstName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.FirstName",
                                                        },
                                                      }),
                                                      _c(
                                                        "slide-y-down-transition",
                                                        [
                                                          _c(
                                                            "md-icon",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: failed,
                                                                  expression:
                                                                    "failed",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "error",
                                                            },
                                                            [_vm._v("close")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "slide-y-down-transition",
                                                        [
                                                          _c(
                                                            "md-icon",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: passed,
                                                                  expression:
                                                                    "passed",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "success",
                                                            },
                                                            [_vm._v("done")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "lastname",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var passed = ref.passed
                                                var failed = ref.failed
                                                return [
                                                  _c(
                                                    "md-field",
                                                    {
                                                      class: [
                                                        { "md-error": failed },
                                                        { "md-valid": passed },
                                                      ],
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("lastName")
                                                          )
                                                        ),
                                                      ]),
                                                      _c("md-input", {
                                                        attrs: { type: "text" },
                                                        model: {
                                                          value:
                                                            _vm.contact
                                                              .LastName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contact,
                                                              "LastName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contact.LastName",
                                                        },
                                                      }),
                                                      _c(
                                                        "slide-y-down-transition",
                                                        [
                                                          _c(
                                                            "md-icon",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: failed,
                                                                  expression:
                                                                    "failed",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "error",
                                                            },
                                                            [_vm._v("close")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "slide-y-down-transition",
                                                        [
                                                          _c(
                                                            "md-icon",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: passed,
                                                                  expression:
                                                                    "passed",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "success",
                                                            },
                                                            [_vm._v("done")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "gender" } },
                                        [_vm._v(_vm._s(_vm.$t("gender")))]
                                      ),
                                      _c(
                                        "md-select",
                                        {
                                          attrs: {
                                            name: "gender",
                                            id: "gender",
                                          },
                                          model: {
                                            value: _vm.contact.Gender,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contact,
                                                "Gender",
                                                $$v
                                              )
                                            },
                                            expression: "contact.Gender",
                                          },
                                        },
                                        [
                                          _c(
                                            "md-option",
                                            { attrs: { value: "männlich" } },
                                            [_vm._v(_vm._s(_vm.$t("male")))]
                                          ),
                                          _c(
                                            "md-option",
                                            { attrs: { value: "weiblich" } },
                                            [_vm._v(_vm._s(_vm.$t("female")))]
                                          ),
                                          _c(
                                            "md-option",
                                            { attrs: { value: "divers" } },
                                            [_vm._v(_vm._s(_vm.$t("diverse")))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("phoneNumber"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.contact.Phone,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.contact, "Phone", $$v)
                                          },
                                          expression: "contact.Phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "email",
                                      rules: "required|email",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var passed = ref.passed
                                            var failed = ref.failed
                                            return [
                                              _c(
                                                "md-field",
                                                {
                                                  class: [
                                                    { "md-error": failed },
                                                    { "md-valid": passed },
                                                  ],
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("email"))
                                                    ),
                                                  ]),
                                                  _c("md-input", {
                                                    attrs: { type: "email" },
                                                    model: {
                                                      value: _vm.contact.Email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contact,
                                                          "Email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.Email",
                                                    },
                                                  }),
                                                  _c(
                                                    "slide-y-down-transition",
                                                    [
                                                      _c(
                                                        "md-icon",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: failed,
                                                              expression:
                                                                "failed",
                                                            },
                                                          ],
                                                          staticClass: "error",
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "slide-y-down-transition",
                                                    [
                                                      _c(
                                                        "md-icon",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: passed,
                                                              expression:
                                                                "passed",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "success",
                                                        },
                                                        [_vm._v("done")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("position"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.contact.Position,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "Position",
                                              $$v
                                            )
                                          },
                                          expression: "contact.Position",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c(
                                        "md-checkbox",
                                        {
                                          staticClass: "imo-checkbox",
                                          model: {
                                            value: _vm.contact.MainContact,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contact,
                                                "MainContact",
                                                $$v
                                              )
                                            },
                                            expression: "contact.MainContact",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("mainContact")) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c("select-country", {
                                    attrs: {
                                      value: _vm.contact.Language.toUpperCase(),
                                    },
                                    on: { changeCountry: _vm.handleCountry },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("createdBy"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text", disabled: "" },
                                        model: {
                                          value: _vm.contact.CreatedBy,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "CreatedBy",
                                              $$v
                                            )
                                          },
                                          expression: "contact.CreatedBy",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("createdOn"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text", disabled: "" },
                                        model: {
                                          value: _vm.contact.Created,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "Created",
                                              $$v
                                            )
                                          },
                                          expression: "contact.Created",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("modifiedBy"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text", disabled: "" },
                                        model: {
                                          value: _vm.contact.ModifiedBy,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "ModifiedBy",
                                              $$v
                                            )
                                          },
                                          expression: "contact.ModifiedBy",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100 md-size-50",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("modifiedOn"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: { type: "text", disabled: "" },
                                        model: {
                                          value: _vm.contact.Modified,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "Modified",
                                              $$v
                                            )
                                          },
                                          expression: "contact.Modified",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass:
                                  "md-layout-item md-size-50 text-right",
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-size-50 text-right remove-flex md-small-size-100",
                                },
                                [
                                  _vm.allow(
                                    "{66f669e2-c677-436c-9afa-0bb0c7f95633}"
                                  )
                                    ? _c(
                                        "md-button",
                                        {
                                          staticClass:
                                            "md-raised md-primary mt-4",
                                          attrs: {
                                            id: "submit",
                                            disabled: invalid,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateContact()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("updateContactText")
                                            ) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
        _vm.verificationObject || _vm.imaStudent2022Exists
          ? _c(
              "md-card",
              {
                staticClass:
                  "student-data md-layout-item md-medium-size-33 md-size-33 md-small-size-100",
              },
              [
                _c("loader", { attrs: { loader: _vm.loaderStudent } }),
                _c(
                  "md-card-header",
                  {
                    staticClass: "md-card-header-icon",
                    class: _vm.getClass(_vm.headerColor),
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("folder_open")])],
                      1
                    ),
                    _c("h4", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(_vm.$t("studentData")) + " "),
                    ]),
                    _c("h3", [_vm._v(_vm._s(_vm.$t("reviewStudentData")))]),
                  ]
                ),
                _c("md-card-content", [
                  _c("div", { staticClass: "md-layout student-data-layout" }, [
                    _c(
                      "div",
                      { staticClass: "md-layout-item md-small-size-100" },
                      [
                        _c(
                          "md-field",
                          [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("studentEmail"))),
                            ]),
                            _c("md-input", {
                              attrs: { type: "text", disabled: "" },
                              model: {
                                value: _vm.studentEmail,
                                callback: function ($$v) {
                                  _vm.studentEmail = $$v
                                },
                                expression: "studentEmail",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "md-field",
                          [
                            _c("label", { staticClass: "status-label" }, [
                              _vm._v("Status"),
                            ]),
                            _c("md-input", {
                              key: _vm.renderKey,
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.StatusObj[_vm.StatusNumber],
                                callback: function ($$v) {
                                  _vm.$set(_vm.StatusObj, _vm.StatusNumber, $$v)
                                },
                                expression: "StatusObj[StatusNumber]",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "md-field",
                          { staticClass: "uni-selection" },
                          [
                            _c("select-vue", {
                              key: _vm.renderKey,
                              staticClass: "uni-selection-dropdown",
                              attrs: {
                                options: this.filteredStudentDomains,
                                placeholder: this.$t("selectUni"),
                              },
                              model: {
                                value: _vm.selectedStudentDomain,
                                callback: function ($$v) {
                                  _vm.selectedStudentDomain = $$v
                                },
                                expression: "selectedStudentDomain",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.StatusNumber === 2
                          ? _c("div", { staticClass: "resend-mail" }, [
                              _c("span", { staticClass: "md-primary" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("resendUniConfirm")) + " "
                                ),
                                _c("a", { on: { click: _vm.resendMail } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("resendMailLink")) + "."
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "md-layout uni-domain-button" },
                          [
                            _c(
                              "div",
                              [
                                _vm.StatusNumber === 3
                                  ? _c(
                                      "md-button",
                                      {
                                        staticClass: "md-raised md-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeVerificationStatus(
                                              "3"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("updateUni")) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.allow(
                              "{7570126c-4a71-4971-aa7f-950977f49729}"
                            ) && _vm.StatusNumber === 3
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass: "md-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "Add Student Domain",
                                            })
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("addUni")) + " ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm.StatusNumber === 4 || _vm.StatusNumber === 6
                          ? _c(
                              "div",
                              { staticClass: "md-layout-item buttons-status4" },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-raised md-primary left-button",
                                    attrs: {
                                      disabled: !(
                                        _vm.selectedStudentDomain > 1
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeVerificationStatus("3")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("verifyStudentEmail")) + " "
                                    ),
                                  ]
                                ),
                                _vm.allow(
                                  "{7570126c-4a71-4971-aa7f-950977f49729}"
                                )
                                  ? _c(
                                      "div",
                                      { staticClass: "md-layout-item" },
                                      [
                                        _c(
                                          "md-button",
                                          {
                                            staticClass: "md-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.push({
                                                  name: "Add Student Domain",
                                                })
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("addUni")) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-raised md-primary right-button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeVerificationStatus("5")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("requestIMA")) + " ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !(_vm.selectedStudentDomain > 1) &&
                        _vm.StatusNumber === 4
                          ? _c("div", [
                              _vm._v(_vm._s(_vm.$t("selectUniReminder"))),
                            ])
                          : _vm._e(),
                        _vm.imaFilesData.length
                          ? _c("h3", [_vm._v(_vm._s(_vm.$t("imaFiles")))])
                          : _vm._e(),
                        _vm._l(_vm.imaFilesData, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "download-ima-section" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "download-ima-name",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadIma(
                                        _vm.imaFilesData[index].ImaFile,
                                        item.Name
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.Name) + " ")]
                              ),
                              _c("div", { staticClass: "ima-actions" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "download-delete-ima",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadIma(
                                          _vm.imaFilesData[index].ImaFile,
                                          item.Name
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "md-icon",
                                      {
                                        staticClass: "download-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadIma(
                                              _vm.imaFilesData[index].ImaFile,
                                              item.Name
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("file_download")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        }),
                        _vm.StatusNumber === 3
                          ? _c("div", [
                              _c("div", [
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.productData[_vm.studentProductID]
                                    )
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "downloads-and-date" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-40",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("numberOfDownloads")
                                                )
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: {
                                                type: "text",
                                                disabled: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.numberOfDownloadsStudentProduct,
                                                callback: function ($$v) {
                                                  _vm.numberOfDownloadsStudentProduct =
                                                    $$v
                                                },
                                                expression:
                                                  "numberOfDownloadsStudentProduct",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-small-size-100 md-size-50",
                                      },
                                      [
                                        _c(
                                          "md-field",
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lastDownloadDate")
                                                )
                                              ),
                                            ]),
                                            _c("md-input", {
                                              attrs: {
                                                type: "text",
                                                disabled: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.lastDownloadDateStudent2024,
                                                callback: function ($$v) {
                                                  _vm.lastDownloadDateStudent2024 =
                                                    $$v
                                                },
                                                expression:
                                                  "lastDownloadDateStudent2024",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    class:
                                      ((_obj = {}),
                                      (_obj["buttons-alignment"] =
                                        _vm.studentPaymentExist),
                                      _obj),
                                  },
                                  [
                                    _vm.studentPaymentExist
                                      ? _c(
                                          "div",
                                          { staticClass: "button-align-left" },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-raised md-primary",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeTestOrderStatus(
                                                      !_vm.testOrder
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("setTestorder") +
                                                      ": " +
                                                      !_vm.testOrder
                                                  ) + " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "flex-inline-licence" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "button-align-left" },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-raised md-primary",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.generateNewLicence(
                                                      "Studienversion 2024"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("generateLicence")
                                                  ) + " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "button-align-right" },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-raised md-primary",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getLicenceFile(
                                                      "Studienversion 2024"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("donwloadLicence")
                                                  ) + " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm.additionalProductID
                                ? _c(
                                    "div",
                                    [
                                      _c("h3", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.productData[
                                              _vm.additionalProductID
                                            ]
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "md-field",
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("numberOfDownloads")
                                              )
                                            ),
                                          ]),
                                          _c("md-input", {
                                            attrs: {
                                              type: "text",
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.numberOfDownloadsRMProduct,
                                              callback: function ($$v) {
                                                _vm.numberOfDownloadsRMProduct =
                                                  $$v
                                              },
                                              expression:
                                                "numberOfDownloadsRMProduct",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "button-align-right" },
                                        [
                                          _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-raised md-primary",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getLicenceFile(
                                                    "RM 2023"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("donwloadLicence")
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm.StatusNumber === 6 ||
                    (_vm.StatusNumber === 2 && this.imaFilesData.length)
                      ? _c(
                          "div",
                          { staticClass: "md-layout-item buttons-status4" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-raised md-primary left-button",
                                attrs: {
                                  disabled: !(_vm.selectedStudentDomain > 1),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeVerificationStatus("3")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("verifyIMA")) + " ")]
                            ),
                            _c(
                              "md-button",
                              {
                                staticClass:
                                  "md-raised md-primary right-button",
                                on: {
                                  click: function ($event) {
                                    _vm.changeVerificationStatus("7") ||
                                      (_vm.StatusNumber === 2 &&
                                        this.imaFilesData.length)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("requestNewIMA")) + " ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !(_vm.selectedStudentDomain > 1) && _vm.StatusNumber === 6
                      ? _c("div", [_vm._v(_vm._s(_vm.$t("selectUniReminder")))])
                      : _vm._e(),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "payment-card manual-payment md-layout-item md-medium-size-66 md-size-66 md-small-size-100",
        class: {
          "center-payments-card": !(
            _vm.verificationObject || _vm.imaStudent2022Exists
          ),
        },
      },
      [
        true
          ? _c(
              "md-card",
              { class: { "no-verificationObj": !_vm.verificationObject } },
              [
                _c("loader", { attrs: { loader: _vm.loaderPayment } }),
                _c(
                  "md-card-header",
                  {
                    staticClass: "md-card-header-icon",
                    class: _vm.getClass(_vm.headerColor),
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("payment")])],
                      1
                    ),
                    _c("h4", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(_vm.$t("payments")) + " "),
                    ]),
                  ]
                ),
                _c("md-card-content", [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("payedProducts"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout" },
                    [
                      _vm._l(_vm.payedProducts, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "show-success-payments md-layout-item md-size-100",
                          },
                          [
                            _c(
                              "div",
                              {
                                key: _vm.renderKey,
                                staticClass:
                                  "md-layout-item md-size-50 md-small-size-100",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.productData[item.ProductID]) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-size-50 md-small-size-100",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.Price.toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    ) +
                                    " Euro "
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "create-payments md-layout-item md-size-100",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-medium-size-50 md-size-50 md-small-size-100",
                              on: {
                                click: function ($event) {
                                  return _vm.addCustomClass()
                                },
                              },
                            },
                            [
                              _c(
                                "md-field",
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "paymentProductID" } },
                                    [_vm._v(_vm._s(_vm.$t("productId")))]
                                  ),
                                  _c(
                                    "md-select",
                                    {
                                      key: _vm.renderKey,
                                      attrs: {
                                        name: "paymentProductID",
                                        id: "paymentProductID",
                                      },
                                      model: {
                                        value: _vm.paymentProductID,
                                        callback: function ($$v) {
                                          _vm.paymentProductID = $$v
                                        },
                                        expression: "paymentProductID",
                                      },
                                    },
                                    [
                                      _c(
                                        "md-option",
                                        {
                                          attrs: {
                                            value:
                                              "{cc20e362-7c96-4c88-b14d-4ff97bd2864b}",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(this.$t("studentProduct"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "md-option",
                                        {
                                          attrs: {
                                            value:
                                              "{1138e27f-99d6-45b4-9ed7-a059bee0fb41}",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$t(
                                                "mDesign2022SecondEdition"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "md-option",
                                        {
                                          attrs: {
                                            value:
                                              "{19437920-8791-4987-b069-8e99c9b080b0}",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$t(
                                                "vdiSoftwareBoltCalculation"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "md-option",
                                        {
                                          attrs: {
                                            value:
                                              "{691a838c-0e9f-11ee-b3bc-0050561ebe44}",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$t(
                                                "mDesignRoloffMatekEdition2023"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-medium-size-50 md-size-50 md-small-size-100",
                            },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("price"))),
                                  ]),
                                  _c("md-input", {
                                    attrs: {
                                      type: "number",
                                      step: "0.1",
                                      min: "0",
                                    },
                                    model: {
                                      value: _vm.productPrice,
                                      callback: function ($$v) {
                                        _vm.productPrice = $$v
                                      },
                                      expression: "productPrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "button-align-right md-layout-item md-size-100",
                        },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-primary create-payment-button",
                              on: {
                                click: function ($event) {
                                  return _vm.createPayment()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("createPayment")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "contact-products md-layout-item",
        class: { "mr-15 md-size-66 center": !_vm.verificationObject },
      },
      [_c("products-list", { attrs: { contactProducts: true } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }