var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100 no-pad" },
        [
          _c(
            "student-daylie-chart",
            {
              key: _vm.renderKey,
              attrs: {
                "chart-data": _vm.dailySalesChart.data,
                "chart-options": _vm.dailySalesChart.options,
                "chart-type": "Line",
                "chart-inside-header": "",
                "background-color": "primary",
              },
            },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-simple md-info md-just-icon",
                  attrs: { slot: "first-button" },
                  on: { click: _vm.refreshData },
                  slot: "first-button",
                },
                [
                  _c("md-icon", [_vm._v("refresh")]),
                  _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                    _vm._v(_vm._s(_vm.$t("refresh"))),
                  ]),
                ],
                1
              ),
              _c("template", { slot: "content" }, [
                _c("h4", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("lastDaysRegistration"))),
                ]),
                _c("p", { staticClass: "category" }, [
                  _vm._v(" " + _vm._s(_vm.$t("today")) + " "),
                  _c(
                    "span",
                    { staticClass: "text-success" },
                    [
                      _c("animated-number", {
                        attrs: { value: _vm.todaysOrdering },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("registrations")) + " "),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "stats" },
                  [
                    _c("md-icon", [_vm._v("access_time")]),
                    _vm._v(" " + _vm._s(_vm.$t("timeTextUpdated") + " ")),
                    _c("animated-number", {
                      key: _vm.elapsedTime,
                      attrs: { value: _vm.elapsedTime },
                    }),
                    _vm._v(
                      _vm._s(
                        " " +
                          (_vm.timeTextHours
                            ? _vm.$t("timeTextHours")
                            : _vm.$t("timeTextMinutes"))
                      ) + " "
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100 no-pad" },
        [
          _c(
            "chart-card",
            {
              key: _vm.renderKeyMonth,
              attrs: {
                "header-animation": "true",
                "chart-data": _vm.emailsSubscriptionChart.data,
                "chart-options": _vm.emailsSubscriptionChart.options,
                "chart-responsive-options":
                  _vm.emailsSubscriptionChart.responsiveOptions,
                "chart-type": "Bar",
                "chart-inside-header": "",
                "background-color": "primary",
              },
            },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-simple md-just-icon",
                  attrs: { slot: "first-button" },
                  on: { click: _vm.decreaseYear },
                  slot: "first-button",
                },
                [
                  _c("md-icon", [_vm._v("remove")]),
                  _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                    _vm._v(_vm._s(_vm.$t("previousYear"))),
                  ]),
                ],
                1
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-simple md-info md-just-icon",
                  attrs: { slot: "second-button" },
                  on: { click: _vm.refreshMonthly },
                  slot: "second-button",
                },
                [
                  _c("md-icon", [_vm._v("refresh")]),
                  _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                    _vm._v(_vm._s(_vm.$t("refresh"))),
                  ]),
                ],
                1
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-simple md-just-icon",
                  attrs: { slot: "third-button" },
                  on: { click: _vm.increaseYear },
                  slot: "third-button",
                },
                [
                  _c("md-icon", [_vm._v("add")]),
                  _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                    _vm._v(_vm._s(_vm.$t("nextYear"))),
                  ]),
                ],
                1
              ),
              _c("template", { slot: "content" }, [
                _c("h4", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("registrationsOfYear") + " " + _vm.selectedYear
                    )
                  ),
                ]),
                _c(
                  "p",
                  { staticClass: "category" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("inTotal") + " ")),
                    _c("animated-number", {
                      attrs: { value: _vm.registrationsPerYear },
                    }),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "stats" },
                  [
                    _c("md-icon", [_vm._v("access_time")]),
                    _vm._v(" " + _vm._s(_vm.$t("timeTextUpdated") + " ")),
                    _c("animated-number", {
                      key: _vm.elapsedTimeMonthly,
                      attrs: { value: _vm.elapsedTimeMonthly },
                    }),
                    _vm._v(
                      _vm._s(
                        " " +
                          (_vm.timeTextHoursMonthly
                            ? _vm.$t("timeTextHours")
                            : _vm.$t("timeTextMinutes"))
                      ) + " "
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100 no-pad" },
        [
          _c(
            "stats-card",
            { key: _vm.renderKeyMonth, attrs: { "header-color": "primary" } },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("store")])],
                  1
                ),
                _c("p", { staticClass: "category" }, [
                  _vm._v(_vm._s(_vm.$t("revenue"))),
                ]),
                _c(
                  "h3",
                  { staticClass: "title" },
                  [
                    _vm._v("€ "),
                    _c("animated-number", { attrs: { value: _vm.yearRev } }),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "stats" },
                  [
                    _c("md-icon", [_vm._v("date_range")]),
                    _vm._v(" " + _vm._s(_vm.$t("inYear") + " ")),
                    _c("animated-number", {
                      attrs: { value: parseInt(_vm.selectedYear) },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }