var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-primary" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("assignment")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [
                  _vm._v("Comparison of versions"),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c("md-table", {
                  staticClass: "table-striped",
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function (ref) {
                        var item = ref.item
                        return _c(
                          "md-table-row",
                          { attrs: { "md-auto-select": "" } },
                          [
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "#" } },
                              [_vm._v(_vm._s(item.id))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Product Name" } },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Student NEW" } },
                              [
                                _c("span", { staticClass: "material-icons" }, [
                                  _vm._v(" " + _vm._s(item.studentNew) + " "),
                                ]),
                              ]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Student Old" } },
                              [
                                _c("span", { staticClass: "material-icons" }, [
                                  _vm._v(" " + _vm._s(item.studentOld) + " "),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.tableDataStriped,
                    callback: function ($$v) {
                      _vm.tableDataStriped = $$v
                    },
                    expression: "tableDataStriped",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }