var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    _c(
      "div",
      { staticClass: "modal-content" },
      [
        _vm.classicModal
          ? _c(
              "modal",
              { attrs: { "no-close-on-backdrop": true } },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.$t("cookieSettingsTitle"))),
                  ]),
                ]),
                _c("template", { staticClass: "modal-body", slot: "body" }, [
                  _c(
                    "div",
                    [
                      _vm._v(" " + _vm._s(_vm.$t("cookiesNoticeText")) + " "),
                      _c(
                        "a",
                        {
                          staticClass: "privacy-policy",
                          attrs: {
                            href: _vm.$t("privacyLink"),
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("privacyPolicy")))]
                      ),
                      _vm._v(". " + _vm._s(_vm.$t("cookiesNoticeText2")) + " "),
                      _c("cookies-settings", {
                        staticClass: "cookies-settings",
                        attrs: { linkToSettings: _vm.$t("settings") },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "template",
                  { slot: "footer" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-primary md-round button-width",
                        on: { click: _vm.classicModalSaveAccept },
                      },
                      [_vm._v(_vm._s(_vm.$t("accept")))]
                    ),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-primary md-round button-width",
                        on: { click: _vm.classicModalSaveDecline },
                      },
                      [_vm._v(_vm._s(_vm.$t("decline")))]
                    ),
                  ],
                  1
                ),
              ],
              2
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }