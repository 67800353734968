var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: [
        { "timeline-inverted": _vm.inverted },
        { "timeline-simple": !_vm.inverted },
      ],
    },
    [
      _vm._t("badge", function () {
        return [
          _c(
            "div",
            { staticClass: "timeline-badge", class: _vm.badgeType },
            [_c("md-icon", [_vm._v(_vm._s(_vm.badgeIcon))])],
            1
          ),
        ]
      }),
      _c("div", { staticClass: "timeline-panel" }, [
        _c("div", { staticClass: "timeline-heading" }, [_vm._t("header")], 2),
        _vm.$slots.content
          ? _c("div", { staticClass: "timeline-body" }, [_vm._t("content")], 2)
          : _vm._e(),
        _vm.$slots.footer ? _c("h6", [_vm._t("footer")], 2) : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }