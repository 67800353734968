var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _c("two-fa-card", { attrs: { "header-color": "primary" } }, [
        _c(
          "div",
          {
            staticClass: "md-card-header-icon md-card-header-default",
            attrs: { slot: "title", "header-color": "primary" },
            slot: "title",
          },
          [
            _c("img", {
              staticClass: "img-title",
              attrs: { src: _vm.logo, alt: "MyMdesignLogo" },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "sucess-text md-layout",
            attrs: { slot: "content" },
            slot: "content",
          },
          [
            _c(
              "h4",
              { staticClass: "title text-center md-layout-item md-size-100" },
              [_vm._v(" " + _vm._s(_vm.titleText) + " ")]
            ),
            _vm.success
              ? _c("p", { staticClass: "md-layout-item md-size-100" }, [
                  _vm._v(" " + _vm._s(_vm.$t("successfulRegistration")) + " "),
                  _c("a", { attrs: { href: "/login" } }, [
                    _vm._v(_vm._s(_vm.$t("link"))),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }