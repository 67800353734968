var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout scroll-behavior" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100",
      },
      [
        _c("loader", { attrs: { loader: _vm.loader } }),
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-primary" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("list")])],
                  1
                ),
                _c("div", { staticClass: "md-title" }, [
                  _vm._v(_vm._s(_vm.$t("awsAccesses"))),
                ]),
                _c("div", { staticClass: "md-subtitle" }, [
                  _vm._v(_vm._s(this.companyStore.company.Name)),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _vm.awsAccounts.length > 0
                  ? _c("md-table", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "md-table-row",
                            fn: function (ref) {
                              var item = ref.item
                              return _c(
                                "md-table-row",
                                {},
                                [
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-sort-by": "id",
                                        "md-label": _vm.$t("name"),
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(_vm._s(item.FleetReadable)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-sort-by": "id",
                                        "md-label": _vm.$t("startDate"),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getReadableDate(item.StartDate)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-sort-by": "id",
                                        "md-label": _vm.$t("endDate"),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getReadableDate(item.EndDate)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": _vm.$t("accessActive"),
                                      },
                                    },
                                    [
                                      _vm.getActive(item.EndDate)
                                        ? _c("md-icon", [_vm._v("close")])
                                        : _c("md-icon", [_vm._v("done")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      staticClass: "fixed-width",
                                      attrs: { "md-label": _vm.$t("controls") },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "controls" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEditAwsAccount(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("edit")]
                                          ),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: {
                                                "md-direction": "bottom",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("edit")))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        2036164223
                      ),
                      model: {
                        value: _vm.awsAccounts,
                        callback: function ($$v) {
                          _vm.awsAccounts = $$v
                        },
                        expression: "awsAccounts",
                      },
                    })
                  : _c("div", { staticClass: "md-center pt-10" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("noConfigFound")))]),
                    ]),
                _c(
                  "div",
                  { staticClass: "text-right pt-10" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-raised md-primary md-round",
                        attrs: { id: "submit" },
                        on: {
                          click: function ($event) {
                            return _vm.addAwsConfig()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }