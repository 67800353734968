var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-technical-dependency-content" },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              return [
                _c(
                  "form",
                  [
                    _c("loader", { attrs: { loader: _vm.loader } }),
                    _c("div", { staticClass: "md-layout" }, [
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-100" },
                        [
                          _c(
                            "md-field",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.autoSelected,
                                    callback: function ($$v) {
                                      _vm.autoSelected = $$v
                                    },
                                    expression: "autoSelected",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("automaticallySelected"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-100" },
                        [
                          _c(
                            "md-field",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.visible,
                                    callback: function ($$v) {
                                      _vm.visible = $$v
                                    },
                                    expression: "visible",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("visible")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "md-layout" }, [
                      !_vm.edit
                        ? _c(
                            "div",
                            { staticClass: "md-layout-item md-size-50" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-round md-primary",
                                  on: { click: _vm.handleCreate },
                                },
                                [_vm._v(_vm._s(_vm.$t("create")))]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "md-layout-item md-size-50" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-round md-primary",
                                  on: { click: _vm.handleSave },
                                },
                                [_vm._v(_vm._s(_vm.$t("save")))]
                              ),
                            ],
                            1
                          ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-50" },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-round md-simple",
                              on: { click: _vm.closeModal },
                            },
                            [_vm._v(_vm._s(_vm.$t("cancel")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }