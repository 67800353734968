var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.closeModal,
                expression: "closeModal",
              },
            ],
            staticClass: "modal-container",
            class: _vm.modalWidthClass,
          },
          [
            _c("div", { staticClass: "modal-header" }, [_vm._t("header")], 2),
            _c(
              "div",
              { staticClass: "modal-body text-center" },
              [_vm._t("body")],
              2
            ),
            _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }