var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar sidebar-second",
      attrs: {
        "data-color": _vm.activeColor,
        "data-background-color": _vm.backgroundColor,
      },
    },
    [
      _c("div", { staticClass: "logo second" }, [
        _c(
          "a",
          {
            staticClass: "simple-text logo-normal",
            staticStyle: { color: "aliceblue" },
            attrs: { href: "http://my.mdesign.de/", target: "_blank" },
          },
          [_c("img", { attrs: { src: _vm.logoBig, alt: "company_big_logo" } })]
        ),
        _c(
          "div",
          { staticClass: "navbar-minimize" },
          [
            _c(
              "md-button",
              {
                staticClass: "md-round md-just-icon md-transparent",
                attrs: { id: "minimizeSidebar" },
                on: { click: _vm.minimizeSidebar },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
            ),
          ],
          1
        ),
      ]),
      this.firstname
        ? _c("div", { staticClass: "logo second" }, [
            _c(
              "a",
              {
                staticClass: "simple-text logo-normal",
                attrs: { renderKey: _vm.updateName },
                on: { click: _vm.goToUserProfile },
              },
              [
                _c("div", { staticClass: "name-surname" }, [
                  _vm._v(_vm._s(_vm.firstname) + " " + _vm._s(_vm.lastname)),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      !this.firstname
        ? _c("div", { staticClass: "logo second" }, [
            _c(
              "a",
              {
                staticClass: "simple-text logo-normal",
                attrs: { renderKey: _vm.updateName },
                on: { click: _vm.goToUserProfile },
              },
              [
                _c("div", { staticClass: "name-surname" }, [
                  _vm._v(_vm._s(_vm.$t("userProfile"))),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { ref: "sidebarScrollArea", staticClass: "sidebar-wrapper" },
        [
          _c(
            "md-list",
            { staticClass: "nav" },
            [
              _vm._t("links", function () {
                return _vm._l(_vm.sidebarLinks, function (link, index) {
                  return _c(
                    "sidebar-item",
                    { key: link.name + index, attrs: { link: link } },
                    _vm._l(link.children, function (subLink, index) {
                      return _c("sidebar-item", {
                        key: subLink.name + index,
                        attrs: { link: subLink },
                      })
                    }),
                    1
                  )
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }