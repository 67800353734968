var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications" },
    [
      _c(
        "transition-group",
        { attrs: { name: "list", mode: "in-out" } },
        _vm._l(_vm.notifications, function (notification) {
          return _c("notification", {
            key: notification.timestamp.getTime(),
            attrs: {
              message: notification.message,
              icon: notification.icon,
              type: notification.type,
              timeout: notification.timeout,
              timestamp: notification.timestamp,
              "vertical-align": notification.verticalAlign,
              "horizontal-align": notification.horizontalAlign,
            },
            on: { "on-close": _vm.removeNotification },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }