var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    { staticClass: "md-card-edit-mdesign" },
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-primary" },
        [
          _c("div", { staticClass: "card-icon" }, [
            this.imgData !== ""
              ? _c("img", {
                  staticClass: "small-icon-header",
                  attrs: { src: this.imgData, title: "" },
                })
              : _c("img", {
                  staticClass: "small-icon-header",
                  attrs: { src: this.regularImg, title: "" },
                }),
          ]),
          _vm._t("header"),
        ],
        2
      ),
      _c("md-card-content", [_vm._t("contentEdit")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }