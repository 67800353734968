var render = function () {
  var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "process-img-container md-layout-item md-size-66 md-small-size-100",
      },
      [
        _c("div", { staticClass: "img-row" }, [
          _c("div", { staticClass: "img-col" }, [
            _vm.selectedColor === "black"
              ? _c("img", {
                  staticClass: "img",
                  attrs: { src: "./../img/student_verification_process/1.png" },
                })
              : _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "./../img/student_verification_process/1_grey.png",
                  },
                }),
          ]),
          _c("div", { staticClass: "img-col" }, [
            _vm.selectedColor === "black"
              ? _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "./../img/student_verification_process/arrow.png",
                  },
                })
              : _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "./../img/student_verification_process/arrow_grey.png",
                  },
                }),
          ]),
          _c("div", { staticClass: "img-col" }, [
            _vm.selectedColor === "black"
              ? _c("img", {
                  staticClass: "img",
                  attrs: { src: "./../img/student_verification_process/2.png" },
                })
              : _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "./../img/student_verification_process/2_grey.png",
                  },
                }),
          ]),
          _c("div", { staticClass: "img-col" }, [
            _vm.selectedColor === "black"
              ? _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "./../img/student_verification_process/arrow.png",
                  },
                })
              : _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "./../img/student_verification_process/arrow_grey.png",
                  },
                }),
          ]),
          _c("div", { staticClass: "img-col" }, [
            _vm.selectedColor === "black"
              ? _c("img", {
                  staticClass: "img",
                  attrs: { src: "./../img/student_verification_process/3.png" },
                })
              : _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "./../img/student_verification_process/3_grey.png",
                  },
                }),
          ]),
          _c("div", { staticClass: "img-col" }, [
            _vm.selectedColor === "black"
              ? _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "./../img/student_verification_process/arrow.png",
                  },
                })
              : _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "./../img/student_verification_process/arrow_grey.png",
                  },
                }),
          ]),
          _c("div", { staticClass: "img-col" }, [
            _vm.selectedColor === "black"
              ? _c("img", {
                  staticClass: "img",
                  attrs: { src: "./../img/student_verification_process/4.png" },
                })
              : _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: "./../img/student_verification_process/4_grey.png",
                  },
                }),
          ]),
        ]),
        _c("div", { staticClass: "img-row img-col-names" }, [
          _c("div", { staticClass: "img-col" }, [
            _vm._v(_vm._s(_vm.infoColName[_vm.langChange][0])),
          ]),
          _c("div", { staticClass: "img-col" }),
          _c("div", { staticClass: "img-col" }, [
            _vm._v(_vm._s(_vm.infoColName[_vm.langChange][1])),
          ]),
          _c("div", { staticClass: "img-col" }),
          _c("div", { staticClass: "img-col" }, [
            _vm._v(_vm._s(_vm.infoColName[_vm.langChange][2])),
          ]),
          _c("div", { staticClass: "img-col" }),
          _c("div", { staticClass: "img-col" }, [
            _vm._v(_vm._s(_vm.infoColName[_vm.langChange][3])),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        key: _vm.langChange,
        staticClass:
          "md-layout-item md-size-66 mx-auto md-medium-size-100 grey-bg",
      },
      [
        _c(
          "tabs",
          {
            staticClass: "page-subcategories",
            attrs: {
              "tab-name": _vm.tabName[_vm.langChange],
              "tab-icon": ["key", "file_download"],
              "nav-pills-icons": "",
              plain: "",
              "color-button": "primary",
            },
          },
          [
            _c(
              "h3",
              {
                staticClass: "title text-center",
                attrs: { slot: "header-title" },
                slot: "header-title",
              },
              [_vm._v(" " + _vm._s(_vm.productName) + " ")]
            ),
            _c(
              "template",
              { slot: "tab-pane-1" },
              [
                _c("loader", { attrs: { loader: _vm.loader } }),
                _c("div", { staticClass: "tiles-sturcture" }, [
                  this.verificationStatus === 1 || !this.verificationStatus
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-info alert-with-icon warning-message",
                          attrs: { "data-notify": "container" },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              attrs: { "data-notify": "icon" },
                            },
                            [_vm._v("add_alert")]
                          ),
                          _c("span", { attrs: { "data-notify": "message" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("warningMassageOnStudentProduct"))
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "timeline-ima" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50",
                      },
                      [
                        _c(
                          "time-line",
                          { attrs: { plain: "", type: "simple" } },
                          [
                            _c(
                              "time-line-item",
                              {
                                class:
                                  ((_obj = {}),
                                  (_obj["badge-in-progress"] =
                                    _vm.confirmStudentEmail),
                                  _obj),
                                attrs: {
                                  inverted: "",
                                  "badge-type": "primary",
                                  "badge-icon": "contact_page",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge badge-primary",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("documents")))]
                                ),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("p", { key: _vm.langChange }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.eductionProofText) +
                                          " "
                                      ),
                                    ]),
                                    [3, 4, 6].includes(this.verificationStatus)
                                      ? _c(
                                          "div",
                                          [
                                            _c("div", {
                                              staticClass: "uploaded-ima-line",
                                            }),
                                            _vm._l(
                                              _vm.imaFilesData,
                                              function (item, index) {
                                                return _c(
                                                  "p",
                                                  {
                                                    key: index,
                                                    staticClass: "uploaded-ima",
                                                  },
                                                  [_vm._v(_vm._s(item.Name))]
                                                )
                                              }
                                            ),
                                            _vm.studentEmailTimeline
                                              ? _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.studentEmailTimeline
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "time-line-item",
                              {
                                class:
                                  ((_obj$1 = {}),
                                  (_obj$1["badge-in-progress"] =
                                    _vm.verificationInProcess &&
                                    _vm.verificationStatus != 3),
                                  _obj$1),
                                attrs: {
                                  inverted: "",
                                  "badge-type": _vm.badgeVerificationInProcess,
                                  "badge-icon": "sync",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class:
                                      ((_obj$2 = {}),
                                      (_obj$2["badge badge-primary"] =
                                        _vm.verificationInProcess),
                                      (_obj$2["badge badge-default"] =
                                        !_vm.verificationInProcess),
                                      _obj$2),
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("verification")))]
                                ),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        " " + _vm._s(_vm.reviewImaText) + " "
                                      ),
                                    ]),
                                    [3].includes(this.verificationStatus)
                                      ? _c("div", [
                                          _c("div", {
                                            staticClass: "uploaded-ima-line",
                                          }),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(_vm.$t("trainingEntity")) +
                                                ": " +
                                                _vm._s(_vm.uniName)
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "time-line-item",
                              {
                                class:
                                  ((_obj$3 = {}),
                                  (_obj$3["badge-in-progress"] =
                                    _vm.verificationApproved &&
                                    !_vm.paymentSuccess),
                                  _obj$3),
                                attrs: {
                                  inverted: "",
                                  "badge-type": _vm.badgeVerificationApproved,
                                  "badge-icon": "shopping_cart",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class:
                                      ((_obj$4 = {}),
                                      (_obj$4["badge badge-primary"] =
                                        _vm.verificationApproved),
                                      (_obj$4["badge badge-default"] =
                                        !_vm.verificationApproved),
                                      _obj$4),
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("productPurchase")))]
                                ),
                                !_vm.paymentSuccess
                                  ? _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("productPurchaseText")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.paymentSuccess
                                  ? _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("productPurchaseSuccess")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "time-line-item",
                              {
                                attrs: {
                                  inverted: "",
                                  "badge-type": _vm.badgePaymentSuccess,
                                  "badge-icon": "done",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class:
                                      ((_obj$5 = {}),
                                      (_obj$5["badge badge-primary"] =
                                        _vm.titlePaymentSuccess),
                                      (_obj$5["badge badge-default"] =
                                        !_vm.titlePaymentSuccess),
                                      _obj$5),
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("licenceDownloadTitle"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.productName +
                                              " " +
                                              _vm.$t("downloadLicense")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50",
                      },
                      [
                        !_vm.verificationApproved
                          ? _c(
                              "md-card",
                              { staticClass: "ima-tile" },
                              [
                                _c("md-card-header", [
                                  _c("h4", { staticClass: "title" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("proveEducationTitle"))
                                    ),
                                  ]),
                                  _c("p", { staticClass: "category" }, [
                                    _vm._v(_vm._s(_vm.productName)),
                                  ]),
                                ]),
                                _c(
                                  "md-card-content",
                                  { staticClass: "content-style" },
                                  [
                                    !_vm.confirmStudentEmail &&
                                    !_vm.studentEmailIsInReview
                                      ? _c(
                                          "div",
                                          { staticClass: "eduction-proof" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "provideEducationProof"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.confirmStudentEmail
                                      ? _c(
                                          "div",
                                          { staticClass: "eduction-proof" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("confirmStudentEmail")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.studentEmailIsInReview
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("studentEmailIsInReview")
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "student-email" },
                                      [
                                        _c("ValidationObserver", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var invalid = ref.invalid
                                                  return [
                                                    !_vm.imaNotReadable
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                attrs: {
                                                                  name: "email",
                                                                  rules: {
                                                                    required: true,
                                                                    email: true,
                                                                    customEmail: true,
                                                                  },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var passed =
                                                                            ref.passed
                                                                          var failed =
                                                                            ref.failed
                                                                          return [
                                                                            _c(
                                                                              "md-field",
                                                                              {
                                                                                class:
                                                                                  [
                                                                                    {
                                                                                      "md-error":
                                                                                        failed,
                                                                                    },
                                                                                    {
                                                                                      "md-valid":
                                                                                        passed,
                                                                                    },
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    required:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "uni-email-label",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "universityEmail"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "md-input",
                                                                                  {
                                                                                    staticClass:
                                                                                      "uni-email-input",
                                                                                    attrs:
                                                                                      {
                                                                                        type: "email",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm.studentEmail,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.studentEmail =
                                                                                              $$v
                                                                                          },
                                                                                        expression:
                                                                                          "studentEmail",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "slide-y-down-transition",
                                                                                  [
                                                                                    _c(
                                                                                      "md-icon",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "show",
                                                                                              rawName:
                                                                                                "v-show",
                                                                                              value:
                                                                                                failed,
                                                                                              expression:
                                                                                                "failed",
                                                                                            },
                                                                                          ],
                                                                                        staticClass:
                                                                                          "error",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "close"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "slide-y-down-transition",
                                                                                  [
                                                                                    _c(
                                                                                      "md-icon",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "show",
                                                                                              rawName:
                                                                                                "v-show",
                                                                                              value:
                                                                                                passed,
                                                                                              expression:
                                                                                                "passed",
                                                                                            },
                                                                                          ],
                                                                                        staticClass:
                                                                                          "success",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "done"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                            _c(
                                                              "md-button",
                                                              {
                                                                staticClass:
                                                                  "md-primary md-round uni-email-button button-pointer",
                                                                attrs: {
                                                                  slot: "footer",
                                                                  disabled:
                                                                    invalid,
                                                                  id: "uniEmail",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.verifyStudentEmail,
                                                                },
                                                                slot: "footer",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "uniEmailButton"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1288826438
                                          ),
                                        }),
                                        _vm.imaNotReadable
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(_vm.$t("imaNotReadable"))
                                              ),
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          [
                                            !_vm.imaNotReadable
                                              ? _c(
                                                  "md-checkbox",
                                                  {
                                                    staticClass: "ima-checkbox",
                                                    model: {
                                                      value: _vm.noUniEmail,
                                                      callback: function ($$v) {
                                                        _vm.noUniEmail = $$v
                                                      },
                                                      expression: "noUniEmail",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("noUniEmail")
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.noUniEmail
                                              ? _c("div", [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "upload-document-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "uploadProofEduction"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "form",
                                                    {
                                                      attrs: {
                                                        enctype:
                                                          "multipart/form-data",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "md-layout-item upload-document",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "file-input",
                                                            },
                                                            [
                                                              !_vm.imageRegular
                                                                ? _c("div", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "image-container",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "md-button",
                                                                          {
                                                                            staticClass:
                                                                              "button-pointer md-fileinput ima-submit",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: _vm.regularImg,
                                                                                    title:
                                                                                      "",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "file",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.onFileChange,
                                                                                  click:
                                                                                    _vm.onInputClick,
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ])
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "image-container",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "md-button",
                                                                        {
                                                                          staticClass:
                                                                            "button-pointer md-fileinput ima-submit",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.imageRegular,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "file",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.onFileChange,
                                                                                click:
                                                                                  _vm.onInputClick,
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                              _vm.pdfName &&
                                                              _vm.imageRegular
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pdf-name",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.pdfName
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "button-container",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "remove-change button-pointer",
                                                                    },
                                                                    [
                                                                      _vm.imageRegular
                                                                        ? _c(
                                                                            "md-button",
                                                                            {
                                                                              staticClass:
                                                                                "md-primary md-round md-fileinput ima-submit",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.uploadEducationProof(
                                                                                      _vm.productID
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "upload"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "md-button",
                                                                        {
                                                                          class:
                                                                            [
                                                                              {
                                                                                "md-simple":
                                                                                  !_vm.imageRegular,
                                                                              },
                                                                              "md-fileinput",
                                                                              "md-round",
                                                                              "button-pointer",
                                                                              "ima-change",
                                                                              {
                                                                                "md-simple":
                                                                                  _vm.imageRegular,
                                                                              },
                                                                            ],
                                                                        },
                                                                        [
                                                                          !_vm.imageRegular
                                                                            ? [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "selectImg"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            : [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "change"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ],
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "file",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.onFileChange,
                                                                                click:
                                                                                  _vm.onInputClick,
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                      _vm.imageRegular
                                                                        ? _c(
                                                                            "md-button",
                                                                            {
                                                                              staticClass:
                                                                                "md-simple md-round button-pointer ima-delete",
                                                                              on: {
                                                                                click:
                                                                                  _vm.removeImage,
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "remove"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.verificationApproved
                          ? _c(
                              "md-card",
                              { staticClass: "ima-tile" },
                              [
                                _c("md-card-header", [
                                  _vm.verificationApproved && _vm.paymentSuccess
                                    ? _c("h4", { staticClass: "title" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("congratulations"))
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.verificationApproved &&
                                  !_vm.paymentSuccess
                                    ? _c("h4", { staticClass: "title" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("paymentProcess"))
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("p", { staticClass: "category" }, [
                                    _vm._v(_vm._s(_vm.productName)),
                                  ]),
                                ]),
                                _c(
                                  "md-card-content",
                                  {
                                    staticClass:
                                      "content-style approved-status",
                                  },
                                  [
                                    _vm.testOrder
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("paymentFromTestAccount")
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.verificationApproved &&
                                    _vm.paymentSuccess &&
                                    !_vm.paymentCookie
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("requestLicenseAllowed")
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.verificationApproved &&
                                    !_vm.paymentSuccess &&
                                    _vm.paymentCookie
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("paymentIsProccessed")
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.verificationApproved &&
                                    !_vm.paymentSuccess &&
                                    !_vm.paymentCookie
                                      ? _c("div", [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.$t("makePaymentText"))
                                            ),
                                          ]),
                                          _c("div", [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "payment-link",
                                                attrs: {
                                                  href: _vm.paymentLink,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm.verificationApproved
                                                  ? _c(
                                                      "md-button",
                                                      {
                                                        class: [
                                                          "md-primary md-round",
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "paymentLink"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm.imaFileNames.length
                    ? _c(
                        "div",
                        { staticClass: "ima-docs" },
                        [
                          _c(
                            "md-card",
                            [
                              _c("md-card-header", [
                                _c(
                                  "h4",
                                  { staticClass: "title-documents-download" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("downloadima")) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._l(_vm.imaFilesData, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "download-ima-section",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "download-ima-name",
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadIma(
                                              _vm.imaFilesData[index].ImaFile,
                                              item.Name
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.Name) + " ")]
                                    ),
                                    _c("div", { staticClass: "ima-actions" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "download-delete-ima",
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadIma(
                                                _vm.imaFilesData[index].ImaFile,
                                                item.Name
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "md-icon",
                                            {
                                              staticClass: "download-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.downloadIma(
                                                    _vm.imaFilesData[index]
                                                      .ImaFile,
                                                    item.Name
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("file_download")]
                                          ),
                                        ],
                                        1
                                      ),
                                      !_vm.verificationApproved
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "download-delete-ima",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteIma(
                                                    item.DataFileID
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "md-icon",
                                                { staticClass: "delete-icon" },
                                                [_vm._v("delete_outline")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
            !_vm.testOrder
              ? _c(
                  "template",
                  { slot: "tab-pane-2" },
                  [
                    _c("loader", { attrs: { loader: _vm.loader } }),
                    _c(
                      "md-card",
                      [
                        _c("md-card-header", [
                          _vm.verificationApproved &&
                          _vm.licenceIsValid &&
                          _vm.paymentSuccess
                            ? _c("div", { staticClass: "licence-and-links" }, [
                                _c("div", { staticClass: "licence-link" }, [
                                  _c("h4", { staticClass: "title2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("program") +
                                          " " +
                                          _vm.productName
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "download-button",
                                      attrs: {
                                        id: "download-link",
                                        href: "https://download.mdesign.de/md2024/240301/173904/stud",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c(
                                        "md-button",
                                        {
                                          class: ["md-primary md-round rings"],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("download")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "licence-link" }, [
                                  _c("h4", { staticClass: "title2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("installationGuide")) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "download-button",
                                      attrs: {
                                        id: "download-link",
                                        href: _vm.$t("installationGuideLink"),
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c(
                                        "md-button",
                                        {
                                          class: ["md-primary md-round rings"],
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("open")) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "licence-link" }, [
                                  _c("h4", { staticClass: "title2" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("licenseFile")) + " "
                                    ),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "download-button",
                                      attrs: { id: "download-link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadLicence(
                                            "Studienversion 2024"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "md-button",
                                        {
                                          class: ["md-primary md-round rings"],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("download")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          !_vm.verificationApproved
                            ? _c("h4", { staticClass: "title2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("provideStudentInfo")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.verificationApproved &&
                          _vm.licenceIsValid &&
                          !_vm.paymentSuccess &&
                          !_vm.paymentCookie
                            ? _c("h4", { staticClass: "title2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("makePaymentText")) + " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.verificationApproved &&
                          _vm.licenceIsValid &&
                          !_vm.paymentSuccess &&
                          _vm.paymentCookie
                            ? _c("h4", { staticClass: "title2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("paymentIsProccessed")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          !_vm.licenceIsValid
                            ? _c("h4", { staticClass: "title2" }, [
                                _vm._v(_vm._s(_vm.$t("​licenceIsExpired"))),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }