var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "select-content" } },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _c(
        "md-card",
        { staticClass: "md-card-select-mdesign" },
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-primary" },
            [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("content_paste")])],
                1
              ),
              _c("h4", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t("templates")) + " "),
              ]),
            ]
          ),
          _c(
            "md-card-content",
            [
              _c("md-table", {
                key: _vm.renderKey,
                staticClass: "md-table-mdesign-configuration",
                scopedSlots: _vm._u([
                  {
                    key: "md-table-row",
                    fn: function (ref) {
                      var item = ref.item
                      return _c(
                        "md-table-row",
                        {},
                        [
                          _c(
                            "md-table-cell",
                            { attrs: { "md-label": _vm.$t("name") } },
                            [_vm._v(_vm._s(item.Info))]
                          ),
                          _c("md-table-cell", { staticClass: "mw-20" }, [
                            _c(
                              "span",
                              {
                                staticClass: "animated-icon",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.reduceCount(item)
                                  },
                                },
                              },
                              [_vm._v("remove")]
                            ),
                          ]),
                          _c(
                            "md-table-cell",
                            {
                              staticClass: "mw-80",
                              attrs: { "md-label": _vm.$t("count") },
                            },
                            [
                              _c(
                                "md-field",
                                { staticClass: "input-field" },
                                [
                                  _c("label"),
                                  _c("md-input", {
                                    attrs: {
                                      inputmode: "numeric",
                                      min: "0",
                                      max: "100",
                                      type: "number",
                                    },
                                    model: {
                                      value: item.Count,
                                      callback: function ($$v) {
                                        _vm.$set(item, "Count", $$v)
                                      },
                                      expression: "item.Count",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("md-table-cell", { staticClass: "mw-20 pr-40" }, [
                            _c(
                              "span",
                              {
                                staticClass: "animated-icon",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addCount(item)
                                  },
                                },
                              },
                              [_vm._v("add")]
                            ),
                          ]),
                          _c(
                            "md-table-cell",
                            {
                              staticClass: "fix-width-25",
                              attrs: { "md-label": _vm.$t("limited") },
                            },
                            [
                              _c(
                                "md-field",
                                { staticClass: "input-field just-cont-sb" },
                                [
                                  item.Enddate !== "9999-12-31"
                                    ? _c("span", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.Enddate,
                                              expression: "item.Enddate",
                                            },
                                          ],
                                          attrs: { type: "date" },
                                          domProps: { value: item.Enddate },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                item,
                                                "Enddate",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ])
                                    : _c(
                                        "span",
                                        {
                                          staticClass: "md-input",
                                          attrs: { type: "text" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("unlimited")))]
                                      ),
                                  _c("div", { staticClass: "actions" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "icon-container",
                                        class: {
                                          selected:
                                            item.Enddate === "9999-12-31",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "animated-icon action-icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.setDate(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" all_inclusive ")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tooltip",
                                            attrs: { id: "tooltip1" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("unlimited")))]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "icon-container" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "animated-icon action-icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteTemplate(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" close ")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tooltip",
                                            attrs: { id: "tooltip1" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("delete")))]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    },
                  },
                ]),
                model: {
                  value: _vm.configurationTemplates,
                  callback: function ($$v) {
                    _vm.configurationTemplates = $$v
                  },
                  expression: "configurationTemplates",
                },
              }),
              _c("div", { staticClass: "md-layout md-alignment-center" }, [
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-simple md-round btn-wi-150",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.backStep()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("back")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      { staticClass: "content-right" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-primary btn-wi-150",
                            attrs: { disabled: !_vm.templateIsSelected },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.nextStep()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("further")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }