var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container" }, [
      _c("nav", [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.$t("contactLink"),
                  target: "_blank",
                  id: "contact",
                },
              },
              [_vm._v(_vm._s(_vm.$t("contact")))]
            ),
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.$t("termsLink"),
                  target: "_blank",
                  id: "terms",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("terms&conditions")) + " ")]
            ),
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.$t("licenseLink"),
                  target: "_blank",
                  id: "license",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("license")) + " ")]
            ),
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: _vm.$t("privacyLink"),
                  id: "privacy",
                },
              },
              [_vm._v(_vm._s(_vm.$t("privacy")))]
            ),
          ]),
          _c("li", [_c("cookie-modal")], 1),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: _vm.$t("cancellationLink"),
                  id: "cancellation",
                },
              },
              [_vm._v(_vm._s(_vm.$t("cancellation")))]
            ),
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: _vm.$t("imprintLink"),
                  id: "imprint",
                },
              },
              [_vm._v(_vm._s(_vm.$t("imprint")))]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }