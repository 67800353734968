var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "nav nav-mobile-menu" },
    [_c("lang-select", { staticClass: "sidebar-language" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }