import { render, staticRenderFns } from "./CreateNewContact.vue?vue&type=template&id=25af60e4&scoped=true"
import script from "./CreateNewContact.vue?vue&type=script&lang=js"
export * from "./CreateNewContact.vue?vue&type=script&lang=js"
import style0 from "./CreateNewContact.vue?vue&type=style&index=0&id=25af60e4&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25af60e4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\GitLab-Runner\\builds\\uYsgRXrL\\0\\mymdesign\\dashboard\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25af60e4')) {
      api.createRecord('25af60e4', component.options)
    } else {
      api.reload('25af60e4', component.options)
    }
    module.hot.accept("./CreateNewContact.vue?vue&type=template&id=25af60e4&scoped=true", function () {
      api.rerender('25af60e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Contacts/CreateNewContact.vue"
export default component.exports