var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100 center-located",
    },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var handleSubmit = ref.handleSubmit
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.submit)
                      },
                    },
                  },
                  [
                    _c("loader", { attrs: { loader: _vm.loader } }),
                    _c(
                      "md-card",
                      { staticClass: "card-content" },
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass: "md-card-header-icon",
                            class: _vm.getClass(_vm.headerColor),
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("cloud")])],
                              1
                            ),
                            _c("h4", { staticClass: "title" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("awsAccountData")) + " "
                              ),
                            ]),
                          ]
                        ),
                        _c("md-card-content", [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("customerNumber"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text", disabled: "" },
                                      model: {
                                        value: _vm.company.CustomerNr,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.company,
                                            "CustomerNr",
                                            $$v
                                          )
                                        },
                                        expression: "company.CustomerNr",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "configType" } },
                                      [_vm._v(_vm._s(_vm.$t("accountType")))]
                                    ),
                                    _c(
                                      "md-select",
                                      {
                                        attrs: {
                                          name: "configType",
                                          id: "configType",
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.configurationAws
                                              .ConfigurationAccountTypeID,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configurationAws,
                                              "ConfigurationAccountTypeID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configurationAws.ConfigurationAccountTypeID",
                                        },
                                      },
                                      _vm._l(
                                        _vm.configurationAccountTypes,
                                        function (configType) {
                                          return _c(
                                            "md-option",
                                            {
                                              key: configType.ID,
                                              attrs: { value: configType.ID },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(configType.Name) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "runtimeinmonth" } },
                                      [_vm._v(_vm._s(_vm.$t("runtime")))]
                                    ),
                                    _c(
                                      "md-select",
                                      {
                                        attrs: {
                                          name: "runtimeinmonth",
                                          id: "runtimeinmonth",
                                          disabled: _vm.expired,
                                        },
                                        model: {
                                          value:
                                            _vm.configurationAws.RuntimeInMonth,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configurationAws,
                                              "RuntimeInMonth",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configurationAws.RuntimeInMonth",
                                        },
                                      },
                                      _vm._l(_vm.runtimes, function (runtime) {
                                        return _c(
                                          "md-option",
                                          {
                                            key: runtime.value,
                                            attrs: { value: runtime.value },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(runtime.label) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("agency"))),
                                    ]),
                                    _c("md-input", {
                                      attrs: { type: "text", disabled: "" },
                                      model: {
                                        value: _vm.creator,
                                        callback: function ($$v) {
                                          _vm.creator = $$v
                                        },
                                        expression: "creator",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", { attrs: { for: "stack" } }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("stackInformation"))
                                      ),
                                    ]),
                                    _c(
                                      "md-select",
                                      {
                                        attrs: {
                                          name: "stack",
                                          id: "stack",
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            _vm.configurationAws.FleetNormal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configurationAws,
                                              "FleetNormal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configurationAws.FleetNormal",
                                        },
                                      },
                                      _vm._l(_vm.stacks, function (stack) {
                                        return _c(
                                          "md-option",
                                          {
                                            key: stack.Stacks,
                                            attrs: { value: stack.Stacks },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(stack.Description) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "countCustomer",
                                    rules: "required|integer",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var passed = ref.passed
                                          var failed = ref.failed
                                          return [
                                            _c(
                                              "md-field",
                                              {
                                                class: [
                                                  { "md-error": failed },
                                                  { "md-valid": passed },
                                                ],
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("countCustomer")
                                                    )
                                                  ),
                                                ]),
                                                _c("md-input", {
                                                  attrs: {
                                                    type: "number",
                                                    disabled: _vm.expired,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.configurationAws
                                                        .Count,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.configurationAws,
                                                        "Count",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "configurationAws.Count",
                                                  },
                                                }),
                                                _c(
                                                  "slide-y-down-transition",
                                                  [
                                                    _c(
                                                      "md-icon",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: failed,
                                                            expression:
                                                              "failed",
                                                          },
                                                        ],
                                                        staticClass: "error",
                                                      },
                                                      [_vm._v("close")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "slide-y-down-transition",
                                                  [
                                                    _c(
                                                      "md-icon",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: passed,
                                                            expression:
                                                              "passed",
                                                          },
                                                        ],
                                                        staticClass: "success",
                                                      },
                                                      [_vm._v("done")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                !_vm.expired
                                  ? _c(
                                      "md-field",
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(_vm.$t("startDate"))),
                                        ]),
                                        _c("md-input", {
                                          attrs: { type: "date", disabled: "" },
                                          model: {
                                            value:
                                              _vm.configurationAws.StartDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.configurationAws,
                                                "StartDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "configurationAws.StartDate",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "md-field",
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(_vm.$t("startDate"))),
                                        ]),
                                        _c("md-input", {
                                          attrs: { type: "text", disabled: "" },
                                          model: {
                                            value:
                                              _vm.configurationAws.StartDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.configurationAws,
                                                "StartDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "configurationAws.StartDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-size-50",
                              },
                              [
                                !_vm.expired
                                  ? _c(
                                      "md-field",
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(_vm.$t("endDate"))),
                                        ]),
                                        _c("md-input", {
                                          attrs: { type: "date", disabled: "" },
                                          model: {
                                            value: _vm.configurationAws.EndDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.configurationAws,
                                                "EndDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "configurationAws.EndDate",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "md-field",
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(_vm.$t("endDate"))),
                                        ]),
                                        _c("md-input", {
                                          attrs: { type: "text", disabled: "" },
                                          model: {
                                            value: _vm.configurationAws.EndDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.configurationAws,
                                                "EndDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "configurationAws.EndDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-size-100 text-right",
                              },
                              [
                                !_vm.expired
                                  ? _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-raised md-primary mt-4 md-round",
                                        attrs: {
                                          id: "submit",
                                          disabled: invalid,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.askUpdate()
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("save")) + " ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "spacer" }),
      !_vm.editAwsUsers
        ? _c(
            "md-card",
            { key: _vm.renderKey, staticClass: "card-content" },
            [
              _c(
                "md-card-header",
                {
                  staticClass: "md-card-header-icon",
                  class: _vm.getClass(_vm.headerColor),
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("people_outline")])],
                    1
                  ),
                  _c("h4", { staticClass: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("awsAccesses")) + " "),
                  ]),
                ]
              ),
              _c(
                "md-card-content",
                [
                  _c(
                    "md-table",
                    {
                      staticClass: "paginated-table table-striped table-hover",
                      attrs: {
                        value: _vm.queriedData,
                        "md-sort": _vm.currentSort,
                        "md-sort-order": _vm.currentSortOrder,
                        "md-sort-fn": _vm.customSort,
                      },
                      on: {
                        "update:mdSort": function ($event) {
                          _vm.currentSort = $event
                        },
                        "update:md-sort": function ($event) {
                          _vm.currentSort = $event
                        },
                        "update:mdSortOrder": function ($event) {
                          _vm.currentSortOrder = $event
                        },
                        "update:md-sort-order": function ($event) {
                          _vm.currentSortOrder = $event
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "md-table-row",
                            fn: function (ref) {
                              var item = ref.item
                              var index = ref.index
                              return _c(
                                "md-table-row",
                                {},
                                [
                                  _c(
                                    "md-table-cell",
                                    { attrs: { "md-label": "#" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.calculatePosition(index)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": _vm.$t("name"),
                                        "md-sort-by": "LastName",
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.FirstName)),
                                      _c("br"),
                                      _c("strong", [
                                        _vm._v(_vm._s(item.LastName)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": _vm.$t("email"),
                                        "md-sort-by": "Email",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.Email))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: { "md-label": _vm.$t("actions") },
                                    },
                                    [
                                      _c(
                                        "md-switch",
                                        {
                                          attrs: { disabled: _vm.expired },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleAwsAccess(item)
                                            },
                                          },
                                          model: {
                                            value: item.IsActive,
                                            callback: function ($$v) {
                                              _vm.$set(item, "IsActive", $$v)
                                            },
                                            expression: "item.IsActive",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("accessActive")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        2382748352
                      ),
                    },
                    [
                      _c("md-table-toolbar", [
                        _c(
                          "div",
                          { staticClass: "toolbar-left" },
                          [
                            _c(
                              "md-field",
                              [
                                _c("div", [
                                  _c(
                                    "label",
                                    { attrs: { for: "pages", id: "perPage" } },
                                    [_vm._v(_vm._s(_vm.$t("perPage")))]
                                  ),
                                ]),
                                _c(
                                  "md-select",
                                  {
                                    attrs: { name: "pages" },
                                    model: {
                                      value: _vm.pagination.perPage,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.pagination, "perPage", $$v)
                                      },
                                      expression: "pagination.perPage",
                                    },
                                  },
                                  _vm._l(
                                    _vm.pagination.perPageOptions,
                                    function (item) {
                                      return _c(
                                        "md-option",
                                        {
                                          key: item,
                                          attrs: { label: item, value: item },
                                        },
                                        [_vm._v(" " + _vm._s(item) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "md-field",
                              { staticClass: "ml-10" },
                              [
                                _c("md-input", {
                                  staticClass: "mb-3",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    type: "search",
                                    clearable: "",
                                    placeholder: _vm.searchRecords,
                                  },
                                  model: {
                                    value: _vm.searchQuery,
                                    callback: function ($$v) {
                                      _vm.searchQuery = $$v
                                    },
                                    expression: "searchQuery",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("md-card-actions", [
                _c("div", { staticClass: "card-actions-container" }, [
                  _c(
                    "div",
                    { staticClass: "button-actions" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-raised md-primary md-round",
                          attrs: { id: "submit", disabled: _vm.expired },
                          on: {
                            click: function ($event) {
                              return _vm.toggleEditUsers()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("contacts")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "table-actions" },
                    [
                      _c("div", {}, [
                        _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("show")) +
                              " " +
                              _vm._s(_vm.from + 1) +
                              " " +
                              _vm._s(_vm.$t("to")) +
                              " " +
                              _vm._s(_vm.to) +
                              " " +
                              _vm._s(_vm.$t("of")) +
                              " " +
                              _vm._s(_vm.total) +
                              " " +
                              _vm._s(_vm.$t("entries")) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("pagination", {
                        staticClass: "pagination-no-border pagination-success",
                        attrs: {
                          "per-page": _vm.pagination.perPage,
                          total: _vm.total,
                        },
                        model: {
                          value: _vm.pagination.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "currentPage", $$v)
                          },
                          expression: "pagination.currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.editAwsUsers
        ? _c("editAwsUsers", {
            attrs: {
              awsIds: _vm.awsIDArray,
              configAWSID: this.configAWSID,
              companyID: this.company.ID,
              contacts: this.allContacts,
              configurationAws: this.configurationAws,
            },
            on: { updateAwsList: _vm.updateAwsList },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }